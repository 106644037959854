var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[(_vm.loader)?_c('div',[_vm._v(" ... ")]):_c('div',{staticClass:"w-full"},[(_vm.buttons)?[_c('div',{staticClass:"p-5 md:p-3"},[(_vm.hashtags.length > 0)?_c('carousel',{staticClass:"carousel-card relative",attrs:{"nav":false,"dots":false,"autoWidth":true,"margin":1,"responsive":{
            0: { items: 1, nav: false },
            340: { items: 2, nav: false },
            650: { items: 3, nav: false },
            800: { items: 4, nav: false }
          }}},[_vm._l((_vm.hashtags),function(hashtag){return _c('t-button',{key:'hashtag-' + hashtag.id,staticClass:"mx-2",class:_vm.activeHashtag === hashtag.id ? 'bg-gray-500 dark:bg-gray-400' : '',attrs:{"variant":"hashtag"},on:{"click":function($event){return _vm.filterByHashtag(hashtag)}}},[_vm._v(" "+_vm._s(hashtag.name ? hashtag.name : hashtag))])}),_c('template',{slot:"prev"},[_c('ArrowCarousel',{attrs:{"direction":"left"}})],1),_c('template',{slot:"next"},[_c('ArrowCarousel',{attrs:{"direction":"right"}})],1)],2):_vm._e()],1)]:_vm._e(),(_vm.buttons)?_vm._l((_vm.groupedPosts),function(group){return _c('div',{key:'video-group-' + group.hashtag.id,staticClass:"mb-5 px-5 md:px-3"},[_c('p',{staticClass:"text-black dark:text-white text-md sm:text-lg md:text-2xl mb-3"},[_vm._v(" "+_vm._s(group.hashtag.name ? group.hashtag.name : group.hashtag)+" ")]),_c('carousel',{staticClass:"carousel-card video-carousel relative",staticStyle:{"background-color":"transparent"},attrs:{"nav":false,"dots":false,"margin":15,"responsive":{
          0: { items: 1, nav: false },
          340: { items: 1, nav: false },
          650: { items: 2, nav: false },
          800: { items: 4, nav: false }
        }}},[_vm._l((group.videos),function(video){return _c('video-preview',{key:("video-" + (video.id)),attrs:{"element":video,"user":video.user},on:{"clickVideo":function($event){return _vm.openPost(video)}}})}),_c('template',{slot:"prev"},[_c('ArrowCarousel',{attrs:{"direction":"left"}})],1),_c('template',{slot:"next"},[_c('ArrowCarousel',{attrs:{"direction":"right"}})],1)],2)],1)}):_vm._e(),(!_vm.buttons)?_c('div',{key:'video-group-cuts-all',staticClass:"mb-5 px-5 md:px-3"},[_c('p',{staticClass:"text-black dark:text-white text-md sm:text-lg md:text-2xl mb-3"},[_vm._v(" JeviCuts ")]),_c('carousel',{staticClass:"carousel-card video-carousel relative",staticStyle:{"background-color":"transparent"},attrs:{"nav":false,"dots":false,"margin":15,"responsive":{
        0: { items: 1, nav: false },
        340: { items: 2, nav: false },
        650: { items: 3, nav: false },
        800: { items: 4, nav: false }
      }}},[_vm._l((_vm.groupedPosts),function(video){return _c('video-preview',{key:("video-" + (video.id) + "-home"),attrs:{"moreInfo":_vm.buttons,"element":video,"user":video.user},on:{"click":function($event){return _vm.openPost(video)},"clickVideo":function($event){return _vm.openPost(video)}}})}),_c('template',{slot:"prev"},[_c('ArrowCarousel',{attrs:{"direction":"left"}})],1),_c('template',{slot:"next"},[_c('ArrowCarousel',{attrs:{"direction":"right"}})],1)],2)],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }