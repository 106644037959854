<template>
  <div class="w-full flex" :class="storyUsers.length === 0 ? 'justify-start' : 'justify-center'">
    <img v-if="loading" class="w-1/2 md:w-1/6 lg:w-1/5 mx-auto" :src="
      theme === 'dark'
        ? '/static/loading_darkmode.gif'
        : '/static/loading_lightmode.gif'
    " alt="loading-gif" />
    <img v-if="!loading" @click="createStory" src="/static/icons/picture_icon.svg"
      class="sidebar-image active mx-1 my-auto cursor-pointer" style="width: 30px; height: 30px; object-fit: cover"
      alt="New story icon" />
    <carousel v-if="!loading && storyUsers.length > 0" :nav="false" :dots="false" class="carousel-card relative w-full"
      :margin="10" :stagePadding="2" :responsive="{
        0: { items: 3, nav: false },
        340: { items: 4, nav: false },
        440: { items: 6, nav: false },
        640: { items: 4, nav: false },
        800: { items: 5, nav: false },
        1200: { items: 6, nav: false },
        1400: { items: 8, nav: false }
      }">
      <div v-for="storyUser in storyUsers" :key="'story-user-' + storyUser.id">
        <div class="flex flex-col justify-center" style="cursor: pointer" @click="openStory(storyUser)">
          <div class="w-14 h-14 mx-auto">
            <profile-picture :data="storyUser" :sizePx="112" :storyBorder="true" />
          </div>
          <p class="text-black dark:text-white text-xs text-center mx-auto" style="
              display: flex;
              align-items: center;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            ">
            {{ storyUser.username.length > 10 ? storyUser.username.substring(0, 10) + '...' : storyUser.username }}
            <!--<span class="ml-1" v-if="storyUser.verified == 1">
              <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
            </span>-->
          </p>
        </div>
      </div>

      <template slot="prev"><span class="absolute bg-white top-0 -left-5 p-2 rounded-lg shadow-xl z-10 cursor-pointer"
          style="border: 1px solid #a0a0a0">
          <icon icon="angle-left" />
        </span></template>
      <template slot="next"><span class="absolute bg-white top-0 -right-5 p-2 rounded-lg shadow-2xl z-10 cursor-pointer"
          style="border: 1px solid #a0a0a0">
          <icon icon="angle-right" />
        </span></template>
    </carousel>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel"
import ProfilePicture from "@/components/user/ProfilePicture.vue"
const notify = () => import("@/utils/notify.js")

export default {
  components: {
    carousel,
    ProfilePicture
  },
  props: {},
  computed: {
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    storyUsers() {
      return this.$store.getters["story/getAllUnpaginated"]
    },
    loadNew() {
      return this.$store.getters["story/getLoadNew"]
    }
  },
  data() {
    return {
      loading: true
    }
  },
  methods: {
    createStory() {
      this.$emit("openStoryCreation")
    },
    openStory(story) {
      this.$emit("openStory", story)
    },
    onImageLoad(element) {
      if (this.$refs["loading-picture"])
        this.$refs["loading-picture"].classList.add("hidden")
      if (this.$refs[element.id] && this.$refs[element.id].classList)
        this.$refs[element.id].classList.remove("hidden")
      if (this.$refs[element.id][0] && this.$refs[element.id][0].classList)
        this.$refs[element.id][0].classList.remove("hidden")
    },
    getUsersWithStories() {
      this.$store
        .dispatch("global/getItems", {
          route: "/story/feed/" + this.loggedUser.id,
          noPaginate: true,
          module: "story",
          params: {
            order_key: "updated_at",
            order_value: "desc",
            active: 1
          }
        })
        .then((response) => {
          if (this.loggedUser.stories_available) {
            this.getUserStories()
          } else {
            this.loading = false
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getUserStories() {
      this.$store
        .dispatch("global/getItems", {
          route: "/story/all",
          noPaginate: true,
          params: {
            id_user: this.loggedUser.id,
            order_key: "created_at",
            order_value: "asc",
            active: 1
          }
        })
        .then((response) => {
          const datax = this.loggedUser
          datax.stories = response.data
          datax.stories_viewed = response.data.every((x) => x.viewed === 1)
            ? 1
            : 0
          this.$store.dispatch("story/addUserStories", datax)
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    }
  },
  created() {
    this.getUsersWithStories()
  },
  watch: {
    storyUsers: {
      handler() {
        if (
          this.storyUsers.length > 0 &&
          this.storyUsers[0].id === this.loggedUser.id
        ) {
          this.loading = false
        }
      },
      deep: true
    },
    loadNew: {
      handler() {
        if (this.loadNew) {
          this.loading = true
          this.getUsersWithStories()
          this.$store.dispatch("story/setLoadNew", false)
        }
      }
    }
  }
}
</script>

<style>
.carousel-card .owl-nav {
  display: none;
}
</style>
