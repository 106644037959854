<template>
    <div>
        <div :class="[direction === 'right' ? 'arrow-right -right-5' : 'arrow-left -left-5']"
            class=" z-10  absolute top-1/3  p-4 bg-purple dark:bg-purple rounded-full shadow-xl cursor-pointer">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        direction: {
            type: String,
            required: true
        },
    }
}
</script>

<style lang="scss" scoped>
div.arrow-left {
    width: 8vmin;
    height: 8vmin;
    box-sizing: border-box;
    transform: rotate(-130deg);

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        border-width: .8vmin .8vmin 0 0;
        border-style: solid;
        border-color: #fafafa;
        transition: .2s ease;
        display: block;
        transform-origin: 100% 0;
    }


    &:after {
        content: '';
        float: left;
        position: relative;
        top: -100%;
        width: 100%;
        height: 100%;
        border-width: 0 .8vmin 0 0;
        border-style: solid;
        border-color: #fafafa;
        transform-origin: 100% 0;
        transition: .2s ease;
    }

    &:hover::after {
        transform: rotate(40deg) translate(5%, 12%);
        border-color: orange;
        height: 120%;
    }

    &:hover::before {
        border-color: orange;
        transform: scale(.8);

    }

}

div.arrow-right {
    width: 8vmin;
    height: 8vmin;
    box-sizing: border-box;
    transform: rotate(45deg);

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        border-width: .8vmin .8vmin 0 0;
        border-style: solid;
        border-color: #fafafa;
        transition: .2s ease;
        display: block;
        transform-origin: 100% 0;
    }


    &:after {
        content: '';
        float: left;
        position: relative;
        top: -100%;
        width: 100%;
        height: 100%;
        border-width: 0 .8vmin 0 0;
        border-style: solid;
        border-color: #fafafa;
        transform-origin: 100% 0;
        transition: .2s ease;
    }

    &:hover::after {
        transform: rotate(45deg) translate(10%, 12%);
        border-color: orange;
        height: 120%;
    }

    &:hover::before {
        border-color: orange;
        transform: scale(.8);

    }

}
</style>