var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[(_vm.loader)?_c('div',[_vm._v(" ... ")]):_c('div',{staticClass:"w-full"},[(_vm.buttons)?_c('div',{staticClass:"mb-5 px-5 md:px-3"},[_c('div',{staticClass:"mb-5 px-5 md:px-3"},[_c('carousel',{staticClass:"carousel-card video-carousel relative",staticStyle:{"background-color":"transparent"},attrs:{"nav":false,"dots":false,"margin":15,"responsive":{
          0: { items: 1, nav: false },
          340: { items: 1, nav: false },
          650: { items: 2, nav: false },
          800: { items: 3, nav: false }
        }}},[_vm._l((_vm.originalPosts),function(video){return _c('video-preview',{key:("video-" + (video.id)),attrs:{"element":video,"user":video.user},on:{"clickVideo":function($event){return _vm.openPost(video)}}})}),_c('template',{slot:"prev"},[_c('ArrowCarousel',{attrs:{"direction":"left"}})],1),_c('template',{slot:"next"},[_c('ArrowCarousel',{attrs:{"direction":"right"}})],1)],2)],1)]):_vm._e(),(!_vm.buttons)?_c('div',{key:'video-group-cuts-all',staticClass:"mb-5 px-5 md:px-3"},[_c('p',{staticClass:"text-black dark:text-white text-md sm:text-lg md:text-2xl mb-3"},[_vm._v(" "+_vm._s(_vm.$i18n.t('content_creator'))+" ")]),_c('carousel',{staticClass:"carousel-card video-carousel relative",staticStyle:{"max-height":"30vh","background-color":"transparent"},attrs:{"nav":false,"dots":false,"margin":15,"responsive":{
          0: { items: 1, nav: false },
          340: { items: 2, nav: false },
          650: { items: 3, nav: false },
          800: { items: 3, nav: false }
        }}},[_vm._l((_vm.originalPosts),function(video){return _c('video-preview',{key:("video-" + (video.id) + "-home"),attrs:{"moreInfo":_vm.buttons,"element":video,"user":video.user},on:{"clickVideo":function($event){return _vm.openPost(video)}}})}),_c('template',{slot:"prev"},[_c('ArrowCarousel',{attrs:{"direction":"left"}})],1),_c('template',{slot:"next"},[_c('ArrowCarousel',{attrs:{"direction":"right"}})],1)],2)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }