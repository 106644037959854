<template>
  <div class="flex">
    <div v-if="loader">
      ...
    </div>
    <div v-else class="w-full">
      <template v-if="buttons">
        <div class="p-5 md:p-3">
          <carousel v-if="hashtags.length > 0" :nav="false" :dots="false" :autoWidth="true"
            class="carousel-card relative" :margin="1" :responsive="{
              0: { items: 1, nav: false },
              340: { items: 2, nav: false },
              650: { items: 3, nav: false },
              800: { items: 4, nav: false }
            }">
            <t-button v-for="hashtag in hashtags" :key="'hashtag-' + hashtag.id" variant="hashtag" class="mx-2"
              :class="activeHashtag === hashtag.id ? 'bg-gray-500 dark:bg-gray-400' : ''"
              @click="filterByHashtag(hashtag)">
              {{ hashtag.name ? hashtag.name : hashtag }}</t-button>
            <template slot="prev">
              <ArrowCarousel direction="left" />
            </template>
            <template slot="next">
              <ArrowCarousel direction="right" />
            </template>
          </carousel>
        </div>
      </template>

      <template v-if="buttons">
        <div v-for="group in groupedPosts" :key="'video-group-' + group.hashtag.id" class="mb-5 px-5 md:px-3">
          <p class="text-black dark:text-white text-md sm:text-lg md:text-2xl mb-3">
            {{ group.hashtag.name ? group.hashtag.name : group.hashtag }}
          </p>
          <carousel :nav="false" :dots="false" class="carousel-card video-carousel relative" :margin="15" :responsive="{
            0: { items: 1, nav: false },
            340: { items: 1, nav: false },
            650: { items: 2, nav: false },
            800: { items: 4, nav: false }
          }" style="background-color: transparent;">
            <video-preview v-for="video in group.videos" :key="`video-${video.id}`" :element="video" :user="video.user"
              @clickVideo="openPost(video)" />

            <template slot="prev">
              <ArrowCarousel direction="left" />
            </template>
            <template slot="next">
              <ArrowCarousel direction="right" />
            </template>
          </carousel>
        </div>
      </template>
      <div v-if="!buttons" :key="'video-group-cuts-all'" class="mb-5 px-5 md:px-3">
        <p class="text-black dark:text-white text-md sm:text-lg md:text-2xl mb-3">
          JeviCuts
        </p>
        <carousel :nav="false" :dots="false" class="carousel-card video-carousel relative" :margin="15" :responsive="{
          0: { items: 1, nav: false },
          340: { items: 2, nav: false },
          650: { items: 3, nav: false },
          800: { items: 4, nav: false }
        }" style="background-color: transparent;">
          <video-preview v-for="video in groupedPosts" @click="openPost(video)" :key="`video-${video.id}-home`"
            :moreInfo="buttons" :element="video" :user="video.user" @clickVideo="openPost(video)" />

          <template slot="prev">
            <ArrowCarousel direction="left" />
          </template>
          <template slot="next">
            <ArrowCarousel direction="right" />
          </template>
        </carousel>
      </div>
    </div>

    <!--<div class="hidden md:block md:w-2/5 lg:w-2/6 pl-5">
      <t-card :variant="theme === 'dark' ? 'dark' : 'light'" class="md:w-2/5 lg:w-2/6 fixed pl-5"
        style="cursor: pointer">
        <template v-slot:header>
          <p class="text-black dark:text-white px-2 text-center font-bold">
            {{ $i18n.t("recommended_videos") }}
          </p>
        </template>

        <div class="my-1 w-full" v-for="post in suggestedVideos.slice(0, itemsAmount)"
          :key="'recommended-post-' + post.id">
          <div class="flex justify-start" @click="openPost(post)">
            <img style="max-width: 100px; aspect-ratio: 1" src="/static/logo_square.png"
              :ref="'loading-picture-' + post.id" />

            <img class="hidden" style="max-width: 100px; aspect-ratio: 1" @load="onImageLoad(post.id)"
              :src="post.url_picture" :ref="'video-preview-' + post.id" alt="Video preview" />
            <div>
              <p class="text-black dark:text-white px-2" style="text-overflow: ellipsis; word-wrap: break-word"
                :style="'font-size: ' + smallFontSize">
                {{
    post.text.length > 90
      ? post.text.slice(0, 90) + "..."
      : post.text
                }}
              </p>
            </div>
          </div>
        </div>
      </t-card>
    </div>-->
  </div>
</template>

<script>
import carousel from "vue-owl-carousel"
import VideoPreview from "@/components/gallery/VideoPreview.vue"
import { debounce } from "lodash"
import ArrowCarousel from "../components/arrowCarousel.vue"
const notify = () => import("@/utils/notify.js")

export default {
  data() {
    return {

      activeHashtag: null,
      loader: true,
      hashtags: [],
      itemsAmount: Math.floor((window.screen.height * 0.75) / 100)
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    groupedPosts() {
      if (!this.buttons) {
        return this.$store.getters["jeviCuts/getAllCuts"]
      }
      if (!this.activeHashtag) {
        return this.$store.getters["post/getAllGrouped3"]
      }
      return this.$store.getters["post/getAllGrouped3"].filter(
        (x) => x.hashtag.id === this.activeHashtag
      )

    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    suggestedVideos() {
      return this.$store.getters["post/getAllSuggestedVideos"]
    },
    smallFontSize() {
      return "0.80rem"
    }
  },
  props: {
    postModal: {
      type: Boolean,
      default: false
    },
    openPostWithEmit: {
      type: Boolean,
      default: false
    },
    buttons: {
      type: Boolean,
      default: true
    },
    currentPost: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    carousel,
    VideoPreview,
    ArrowCarousel
  },
  methods: {
    onImageLoad(id) {
      if (this.$refs["loading-picture-" + id][0])
        this.$refs["loading-picture-" + id][0].classList.add("hidden")
      if (this.$refs["video-preview-" + id][0])
        this.$refs["video-preview-" + id][0].classList.remove("hidden")
    },
    filterByHashtag(hashtag) {
      if (this.activeHashtag === hashtag.id) {
        this.activeHashtag = null
      } else {
        this.activeHashtag = hashtag.id
      }
    },
    openPost(post) {
      if (this.openPostWithEmit) return this.$emit('openPost', post)
      this.$emit("update:currentPost", post)
      this.$emit("update:postModal", true)
      this.$socket.emit("post_viewed", {
        id_user: this.loggedUser.id,
        id_post: post.id
      })
    },
    getGroupedPosts() {
      if (this.buttons) return
      this.loader = true
      this.$store
        .dispatch("global/getItems", {
          route: "/post/videos_suggestions",
          module: null,
          noPaginate: true,
          params: {
            active: 1,
            page: 1,
            limit: 10
          }
        })
        .then((response) => {
          this.$store.dispatch("jeviCuts/setJeviCuts", response.data)
          let hashtags = response.data.map((x) => x.hashtag)
          this.hashtags =
            hashtags.length > 10 ? hashtags.slice(0, 10) : hashtags
          this.$emit("finishLoading")
          this.loader = false
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getCutsWithHashtag() {
      if (!this.buttons) return
      this.loader = true
      this.$store
        .dispatch("global/getItems", {
          route: "/post/hashtag/grouped_new",
          module: null,
          noPaginate: true,
          params: {
            active: 1
          }
        })
        .then((response) => {
          this.$store.dispatch("post/setGroupedElements3", response.data)
          let hashtags = response.data.map((x) => x.hashtag)
          this.hashtags =
            hashtags.length > 10 ? hashtags.slice(0, 10) : hashtags
          this.loader = false
          this.$emit("finishLoading")
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    debouncedResize() {
      this.itemsAmount = Math.floor((window.screen.height * 0.75) / 100)
    }
  },
  created() {
    this.$emit("startLoading")
    this.getCutsWithHashtag()
    this.getGroupedPosts()
    window.addEventListener("resize", debounce(this.debouncedResize, 500))
  },
  beforeDestroy() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },
  unmounted() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },

  watch: {}
}
</script>
<style>
.carousel-card .owl-nav {
  display: none;
}

.video-carousel .owl-carousel .owl-item {
  width: 274px !important;
}

/*
.active-hashtag {
  @apply bg-gray-500 dark: bg-gray-400;
}
*/
</style>
