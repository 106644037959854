var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex",class:_vm.storyUsers.length === 0 ? 'justify-start' : 'justify-center'},[(_vm.loading)?_c('img',{staticClass:"w-1/2 md:w-1/6 lg:w-1/5 mx-auto",attrs:{"src":_vm.theme === 'dark'
      ? '/static/loading_darkmode.gif'
      : '/static/loading_lightmode.gif',"alt":"loading-gif"}}):_vm._e(),(!_vm.loading)?_c('img',{staticClass:"sidebar-image active mx-1 my-auto cursor-pointer",staticStyle:{"width":"30px","height":"30px","object-fit":"cover"},attrs:{"src":"/static/icons/picture_icon.svg","alt":"New story icon"},on:{"click":_vm.createStory}}):_vm._e(),(!_vm.loading && _vm.storyUsers.length > 0)?_c('carousel',{staticClass:"carousel-card relative w-full",attrs:{"nav":false,"dots":false,"margin":10,"stagePadding":2,"responsive":{
      0: { items: 3, nav: false },
      340: { items: 4, nav: false },
      440: { items: 6, nav: false },
      640: { items: 4, nav: false },
      800: { items: 5, nav: false },
      1200: { items: 6, nav: false },
      1400: { items: 8, nav: false }
    }}},[_vm._l((_vm.storyUsers),function(storyUser){return _c('div',{key:'story-user-' + storyUser.id},[_c('div',{staticClass:"flex flex-col justify-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openStory(storyUser)}}},[_c('div',{staticClass:"w-14 h-14 mx-auto"},[_c('profile-picture',{attrs:{"data":storyUser,"sizePx":112,"storyBorder":true}})],1),_c('p',{staticClass:"text-black dark:text-white text-xs text-center mx-auto",staticStyle:{"display":"flex","align-items":"center","text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"}},[_vm._v(" "+_vm._s(storyUser.username.length > 10 ? storyUser.username.substring(0, 10) + '...' : storyUser.username)+" ")])])])}),_c('template',{slot:"prev"},[_c('span',{staticClass:"absolute bg-white top-0 -left-5 p-2 rounded-lg shadow-xl z-10 cursor-pointer",staticStyle:{"border":"1px solid #a0a0a0"}},[_c('icon',{attrs:{"icon":"angle-left"}})],1)]),_c('template',{slot:"next"},[_c('span',{staticClass:"absolute bg-white top-0 -right-5 p-2 rounded-lg shadow-2xl z-10 cursor-pointer",staticStyle:{"border":"1px solid #a0a0a0"}},[_c('icon',{attrs:{"icon":"angle-right"}})],1)])],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }