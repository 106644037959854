<template>
  <div>
    <div v-if="!showDetails">
      <div v-if="highlight && highlight.id" class="">
        <div class="w-full flex justify-between">
          <div class="w-10 flex justify-center"></div>

          <div class="flex flex-col justify-center align-center py-3">
            <h2
              class="text-center mx-auto my-auto text-3xl text-black dark:text-white select-none"
            >
              <strong>{{  highlight[loggedUser.lang+'_title'] }}</strong>
            </h2>
            <p
              class="text-black dark:text-white mx-auto my-auto text-center text-sm sm:text-sm" v-html="$detectHashtagsAndUsernames(highlight[loggedUser.lang+'_description'])"
                @click="handleProcessedTextClick"
            >
            </p>
          </div>

          <div class="flex flex-col justify-center align-center py-auto">
            <t-dropdown>
              <div slot="trigger" slot-scope="{
                  mousedownHandler,
                  focusHandler,
                  blurHandler,
                  keydownHandler
                }" class="flex">
                <button
                  class="flex items-center justify-center flex-shrink-0 px-3 py-2 text-black dark:text-white bg-transparent rounded-r"
                  @mousedown="mousedownHandler" @focus="focusHandler" @blur="blurHandler" @keydown="keydownHandler">
                  <icon icon="ellipsis-v" class="my-auto text-black dark:text-white text-xl sm:text-2xl"
                    style="cursor: pointer"></icon>
                </button>
              </div>

              <div slot-scope="{ blurHandler }">
                <button
                  class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                  role="menuitem" @blur="blurHandler" @click="copyLink">
                  <span class="text-black dark:text-white">{{
                      $i18n.t("copy_link")
                  }}</span>
                </button>
              </div>
            </t-dropdown>
          </div>
        </div>

        <div class="flex justify-center">
          <div :class="highlight.user ? 'cursor-pointer' : ''" @click="handleClick(highlight)">
            <div
              v-if="highlight.user"
              class="pb-2 px-5 z-10"
              :style="{
                position: 'relative',
                left: '2%',
                top: '95%',
                'margin-left': '0px',
                'margin-top': '-60px',
              }"
            >
              <div class="w-full flex justify-start" style="align-items: center;">
                <div class="flex">
                  <div class="w-14 h-14">
                    <profile-picture :data="highlight.user" :sizePx="128" />
                  </div>

                  <div class="ml-3 my-auto">
                    <p class="text-black dark:text-white text-sm sm:text-md" style="font-weight: 700; display: flex; align-items: center;">
                      {{ highlight.user.username }}
                      <span class="ml-1" v-if="highlight.user.verified == 1">
                        <img src="/static/user_verified.png" style="
                              display: flex;
                              height: 16px;
                              width: 16px;
                          " />
                        <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <img
              :alt="highlight[loggedUser.lang+'_description']"
              class="mx-auto col-span-1 object-cover px-2 py-2"
              style="aspect-ratio: 1; max-height: 20vh; max-width: (100 / highlight.links.length) + '%'; border-radius: 10px"
              src="/static/logo_square.png"
              :ref="'loading-main-picture-' + highlight.id"
            />
            <img
              :alt="highlight[loggedUser.lang+'_description']"
              class="hidden mx-auto col-span-1 object-cover px-2 py-2"
              style="aspect-ratio: 1; max-width: 100%; border-radius: 30px"
              :style="{ opacity: (highlight.opacity / 100) }"
              @load="onMainImageLoad(highlight)"
              :src="highlight.imgkit_url_image ? highlight.imgkit_url_image : highlight.url_image"
              :ref="'main-picture-'+highlight.id"
            />
          </div>
        </div>
        
        <div class="flex justify-start">
          <div v-for="element of highlight.links" :key="element.id">
            <a :href="element.url" target="_blank" class="cursor-pointer">
              <!--<icon
                v-if="[2, 5].includes(element.type)"
                icon="play"
                class="text-white text-2xl"
                style="
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  margin-left: -10.5px;
                  margin-top: -12px;
                "
              />-->
              <img
                v-if="element.type !== 5"
                :alt="element.url"
                class="mx-auto col-span-1 object-cover px-2 py-2"
                style="aspect-ratio: 1; max-height: 20vh; max-width: (100 / highlight.links.length) + '%'; border-radius: 30px"
                src="/static/logo_square.png"
                :ref="'loading-link-picture-' + element.id"
              />
              <!--<img
                v-if="element.type === 2"
                :alt="element.url"
                class="hidden mx-auto col-span-1 object-cover px-2 py-2"
                style="aspect-ratio: 1; cursor: pointer"
                :src="element.imgkit_url_image ? element.imgkit_url_image : element.url_image"
                @load="onImageLoad(element)"
                :ref="element.id"
              />-->
              <img
                v-if="element.type === 1"
                :alt="element.url"
                class="hidden mx-auto col-span-1 object-cover px-2 py-2"
                style="aspect-ratio: 1; max-height: 20vh; max-width: (100 / highlight.links.length) + '%'; border-radius: 30px"
                @load="onImageLoad(element)"
                :src="element.imgkit_url_image ? element.imgkit_url_image : element.url_image"
                :ref="'link-picture-'+element.id"
              />
              <!--<video
                v-if="element.type === 5"
                height="100%"
                width="auto"
                class="mx-auto px-2 py-2 h-full w-auto object-cover"
                style="aspect-ratio: 1"
                :src="element.imgkit_url_video || element.url_video"
                controlsList="nodownload"
              />-->
            </a>
          </div>
        </div>

        <div v-if="highlight.post">
          <ActionBar :noAuth="loggedUser ? false : true" :element="highlight.post" :allowLike="allowLike"
            :allowComments="true" :allowSubscription="false" :OAuthPreferences="OAuthPreferences"
            :isLoadingSubscription="false" :calculateHighNumber="calculateHighNumber" @likePost="$emit('likePost', highlight.post)"
            @openModal="openLikesModal" @toggleComments="$emit('toggleComments', highlight.post)" @sharePost="$emit('sharePost', highlight.post)"
            @subscribeChannel="$emit('subscribeChannel', highlight.post)"
            :id="loggedUser ? loggedUser.id : null" @openProfile="(id_user) => {$emit('openProfile', id_user)}" />

          <CommentsSection v-if="true" :showComments="showComments" :element="highlight.post"
            :loggedUserID="loggedUser ? loggedUser.id : null" @openProfile="$emit('openProfile', highlight.post)"
            @updateCommentText="$emit('updateCommentText', highlight.post)" @toggleComments="$emit('toggleComments', highlight.post)" @commentDeleted="$emit('commentDeleted', highlight.post)"
            @scrollToBottom="$emit('scrollToBottom', highlight.post)" />
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="highlight && highlight.id" class="w-full flex justify-center">
        <div class="flex justify-center  flex-col md:flex-row" style="max-width: min(850px, 95vw)">
          <div class="w-full md:w-3/5">
            <t-card ref="highlight-card" :variant="theme === 'dark' ? 'dark' : 'light'" class="py-3 px-0 md:px-2">
              <div v-if="highlight && highlight.id" class="">
                <div class="w-full flex justify-between">
                  <div class="w-10 flex justify-center"></div>

                  <div class="flex flex-col justify-center align-center py-3">
                    <h2
                      class="text-center mx-auto my-auto text-3xl text-black dark:text-white select-none"
                    >
                      <strong>{{  highlight[lang+'_title'] }}</strong>
                    </h2>
                    <p
                      class="text-black dark:text-white mx-auto my-auto text-center text-sm sm:text-sm" v-html="$detectHashtagsAndUsernames(highlight[lang+'_description'])"
                        @click="handleProcessedTextClick"
                    >
                    </p>
                  </div>

                  <div class="flex flex-col justify-center align-center py-auto">
                    <t-dropdown>
                      <div slot="trigger" slot-scope="{
                          mousedownHandler,
                          focusHandler,
                          blurHandler,
                          keydownHandler
                        }" class="flex">
                        <button
                          class="w-10 flex items-center justify-center flex-shrink-0 px-3 py-2 text-black dark:text-white bg-transparent rounded-r"
                          @mousedown="mousedownHandler" @focus="focusHandler" @blur="blurHandler" @keydown="keydownHandler">
                          <icon icon="ellipsis-v" class="my-auto text-black dark:text-white text-xl sm:text-2xl"
                            style="cursor: pointer"></icon>
                        </button>
                      </div>

                      <div slot-scope="{ blurHandler }">
                        <button
                          class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                          role="menuitem" @blur="blurHandler" @click="copyLink">
                          <span class="text-black dark:text-white">{{
                              $i18n.t("copy_link")
                          }}</span>
                        </button>
                      </div>
                    </t-dropdown>
                  </div>
                </div>

                <div class="flex justify-center">
                  <div :class="highlight.user ? 'cursor-pointer' : ''" @click="handleClick(highlight)">
                    <div
                      v-if="highlight.user"
                      class="pb-2 px-5 z-10"
                      :style="{
                        position: 'relative',
                        left: '2%',
                        top: '95%',
                        'margin-left': '0px',
                        'margin-top': '-60px',
                      }"
                    >
                      <div class="w-full flex justify-start" style="align-items: center;">
                        <div class="flex">
                          <div class="w-14 h-14">
                            <profile-picture :data="highlight.user" :sizePx="128" />
                          </div>

                          <div class="ml-3 my-auto">
                            <p class="text-black dark:text-white text-sm sm:text-md" style="font-weight: 700; display: flex; align-items: center;">
                              {{ highlight.user.username }}
                              <span class="ml-1" v-if="highlight.user.verified == 1">
                                <img src="/static/user_verified.png" style="
                                      display: flex;
                                      height: 16px;
                                      width: 16px;
                                  " />
                                <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <img
                      :alt="highlight[lang+'_description']"
                      class="mx-auto col-span-1 object-cover px-2 py-2"
                      style="aspect-ratio: 1; max-height: 20vh; max-width: (100 / highlight.links.length) + '%'; border-radius: 10px"
                      src="/static/logo_square.png"
                      :ref="'loading-main-picture-' + highlight.id"
                    />
                    <img
                      :alt="highlight[lang+'_description']"
                      class="hidden mx-auto col-span-1 object-cover px-2 py-2"
                      style="aspect-ratio: 1; max-width: 100%; border-radius: 30px"
                      :style="{ opacity: (highlight.opacity / 100) }"
                      @load="onMainImageLoad(highlight)"
                      :src="highlight.imgkit_url_image ? highlight.imgkit_url_image : highlight.url_image"
                      :ref="'main-picture-'+highlight.id"
                    />
                  </div>
                </div>
                
                <div class="flex justify-start">
                  <div v-for="element of highlight.links" :key="element.id">
                    <a :href="element.url" target="_blank" class="cursor-pointer">
                      <!--<icon
                        v-if="[2, 5].includes(element.type)"
                        icon="play"
                        class="text-white text-2xl"
                        style="
                          position: absolute;
                          left: 50%;
                          top: 50%;
                          margin-left: -10.5px;
                          margin-top: -12px;
                        "
                      />-->
                      <img
                        v-if="element.type !== 5"
                        :alt="element.url"
                        class="mx-auto col-span-1 object-cover px-2 py-2"
                        style="aspect-ratio: 1; max-height: 20vh; max-width: (100 / highlight.links.length) + '%'; border-radius: 30px"
                        src="/static/logo_square.png"
                        :ref="'loading-link-picture-' + element.id"
                      />
                      <!--<img
                        v-if="element.type === 2"
                        :alt="element.url"
                        class="hidden mx-auto col-span-1 object-cover px-2 py-2"
                        style="aspect-ratio: 1; cursor: pointer"
                        :src="element.imgkit_url_image ? element.imgkit_url_image : element.url_image"
                        @load="onImageLoad(element)"
                        :ref="element.id"
                      />-->
                      <img
                        v-if="element.type === 1"
                        :alt="element.url"
                        class="hidden mx-auto col-span-1 object-cover px-2 py-2"
                        style="aspect-ratio: 1; max-height: 20vh; max-width: (100 / highlight.links.length) + '%'; border-radius: 30px"
                        @load="onImageLoad(element)"
                        :src="element.imgkit_url_image ? element.imgkit_url_image : element.url_image"
                        :ref="'link-picture-'+element.id"
                      />
                      <!--<video
                        v-if="element.type === 5"
                        height="100%"
                        width="auto"
                        class="mx-auto px-2 py-2 h-full w-auto object-cover"
                        style="aspect-ratio: 1"
                        :src="element.imgkit_url_video || element.url_video"
                        controlsList="nodownload"
                      />-->
                    </a>
                  </div>
                </div>

                <div v-if="highlight.post">
                  <ActionBar :noAuth="loggedUser ? false : true" :element="highlight.post" :allowLike="allowLike"
                    :allowComments="true" :allowSubscription="false" :OAuthPreferences="OAuthPreferences"
                    :isLoadingSubscription="false" :calculateHighNumber="calculateHighNumber" @likePost="$emit('likePost', highlight.post)"
                    @openModal="openLikesModal" @toggleComments="$emit('toggleComments', highlight.post)" @sharePost="$emit('sharePost', highlight.post)"
                    @subscribeChannel="$emit('subscribeChannel', highlight.post)"
                    :id="loggedUser ? loggedUser.id : null" @openProfile="$emit('openProfile', highlight.post.user)" />
                </div>
              </div>
            </t-card>
          </div>

          <div v-if="highlight.post" class="w-full md:w-2/5 flex flex-col h-full content-between px-2 mt-2">
            <comments @openProfile="$emit('openProfile', highlight.post)" :post="highlight.post" :showNewComment="true" :height="height"
              @commentDeleted="highlight.post.total_comments--" />
          </div>
        </div>
      </div>
    </div>

    <LikedByModal :modal="likesModal" :postID="highlight.post.id" @closeModal="closeLikesModal" @openProfile="openProfileFromLikesModal" />
  </div>
</template>

<script>

import ProfilePicture from "@/components/user/ProfilePicture.vue"
import Likes from "@/components/post/likes.vue"
import LikedByModal from "@/components/post/LikedByModal.vue"
import ActionBar from "@/components/post/ActionBar.vue"
import CommentsSection from "@/components/post/CommentsSection.vue"
import Comments from "@/components/post/comments.vue"

const notify = () => import("@/utils/notify.js")

export default {
  components: {
    ProfilePicture,
    ActionBar,
    Likes,
    LikedByModal,
    CommentsSection,
    Comments
  },
  props: {
    sizePx: {
      type: Number,
      default: -1
    },
    showDetails: {
      type: Boolean,
      default: false
    },
    element: {
      type: Object,
      required: true
    },
    id: {
      default: -1,
      type: Number
    },
    noAuth: {
      type: Boolean,
      default: false
    },
    showComments: {
      type: Boolean,
      default: false
    },
    OAuthPreferences: {
      type: Object,
      default: null
    },
    allowLike: {
      type: Boolean,
      default: false
    },
    calculateHighNumber: {
      type: Function
    },
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    lang() {
      return (this.$route.query.lang || this.loggedUser.lang) || "en"
    },
    highlight() {
      return this.$store.getters["highlight/getItem"]
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
  },
  data() {
    return {
      height: '100%',
      thumbnail_url: "",
      postID: 0,
      likesModal: false,
    }
  },
  methods: {
    handleProcessedTextClick(e) {
      /*if (e.target.matches(".post-hashtag")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/hashtag/all",
            module: null,
            noPaginate: true,
            isMaster: true,
            params: {
              //query: e.target.innerHTML.slice(1),
              name: e.target.innerHTML,
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.$router.push("/post_by_hashtag/" + response.data[0].name.slice(1))
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
      if (e.target.matches(".post-username")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/user/all",
            module: null,
            noPaginate: true,
            params: {
              username: e.target.innerHTML.slice(1),
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.openProfile(response.data[0])
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }*/
    },
    handleClick(highlight) {
      if (highlight.user) {
        this.$router.push("/user/"+highlight.user.username)
      }
    },
    onImageLoad(element) {
      if (this.$refs["loading-link-picture-" + element.id][0])
        this.$refs["loading-link-picture-" + element.id][0].classList.add("hidden")
      if (this.$refs['link-picture-'+element.id][0])
        this.$refs['link-picture-'+element.id][0].classList.remove("hidden")
    },
    onMainImageLoad(element) {
      if (this.$refs["loading-main-picture-" + element.id])
        this.$refs["loading-main-picture-" + element.id].classList.add("hidden")
      if (this.$refs['main-picture-'+element.id])
        this.$refs['main-picture-'+element.id].classList.remove("hidden")
    },
    getHighlightsByUser() {
      this.$store
        .dispatch("global/getItem", {
          route: "/highlight",
          id: 'byuser',
          module: 'highlight',
        })
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getHighlightByDatetime() {
      this.$store
        .dispatch("global/getItem", {
          route: `/highlight/find_by_timestamp`,
          id: this.$route.params.datetime,
          module: 'highlight',
        })
        .then((response) => {
          if (response.code == 200) {
            this.$emit('finishLoading')
          } else {
            if (response.code === 404) return this.$router.push("/notfound")
            
            notify().then(({ notification }) => {
              notification(
                "error",
                response.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          }
        })
        .catch((error) => {
          this.$emit('finishLoading')
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    copyLink() {
      let created_at = this.highlight.created_at.split('-').join('').split(':').join('').split(' ').join('')

      let link = 'https://' + location.hostname + '/share/highlight/' + created_at + '?lang=' + (this.loggedUser ? this.loggedUser.lang : 'en')

      navigator.clipboard.writeText(link);

      notify().then(({ notification }) => {
        notification("text_copied", 200, this.$snotify, this.$i18n.locale);
      });
    },
    getHeight() {
      const windowWidth = window.innerWidth
      const card = this.$refs['highlight-card']
     
      if (windowWidth < 768 || !card) {
        this.height = '100%'
        return
      }
      
      const clientHeight = card.$el.clientHeight

      this.height = (clientHeight - 55) + 'px'
    },
    openLikesModal(postID) {
      this.postID = postID
      this.likesModal = true
    },
    closeLikesModal() {
      this.likesModal = false
      this.postID = 0
    },
    openProfileFromLikesModal(user) {
      this.likesModal = false
      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
  },
  created() {
    setInterval(() => {
      this.getHeight()
    }, 100)

    if (!this.showDetails) {
      this.getHighlightsByUser()
    } else {
      this.getHighlightByDatetime()
    }
  }
}
</script>
