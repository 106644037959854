<template>
  <div class="relative">
    <t-card :variant="theme === 'dark' ? 'jevi-dark' : 'light'" class="mx-auto w-full"
      :class="'md:' + cardWidth + ' ' + cardOffset" style="z-index: 20">
      <div class="flex justify-around">
        <div @click="showFollowModals && canView ? openFollowModal('followers') : null"
          :style="showFollowModals && canView ? 'cursor: pointer' : ''">
          <p class="mt-3 text-black dark:text-white text-center font-bold"
            :style="smallFont ? 'font-size: ' + smallFontSize : ''">
            {{ calculateHighNumber(user.followers) }}
          </p>
          <p class="text-black dark:text-white text-center" :style="smallFont ? 'font-size: ' + smallFontSize : ''">
            {{ $i18n.t("followers") }}
          </p>
        </div>
        <div :style="
          $route.path.includes('/user') || $route.path.includes('/profile')
            ? 'margin-top: -5rem'
            : 'margin-top: -3rem'
        " class="text-center" @click="clickToProfile ? $emit('click') : null">
          <div class="mx-auto" :class="
            profilePictureSize ? `w-${profilePictureSize} h-${profilePictureSize}` : ''
          ">
            <profile-picture :data="user" :canEdit="canEdit" :canView="canView" @userUpdate="handleUserUpdate"
              :sizePx="(parseInt(profilePictureSize) / 4) * 16 * 2" :storyBorder="user.stories_available === 1"
              @openStory="openStory" />
          </div>

          <p class="text-center text-black dark:text-white" :style="smallFont ? 'font-size: ' + smallFontSize : ''">
            {{ user.full_name }}
          </p>
        </div>

        <div @click="showFollowModals && canView ? openFollowModal('following') : null"
          :style="showFollowModals && canView ? 'cursor: pointer' : ''">
          <p class="mt-3 text-black dark:text-white text-center font-bold"
            :style="smallFont ? 'font-size: ' + smallFontSize : ''">
            {{ calculateHighNumber(user.following) }}
          </p>
          <p class="text-black dark:text-white text-center" :style="smallFont ? 'font-size: ' + smallFontSize : ''">
            {{ $i18n.t("following") }}
          </p>
        </div>
      </div>
      <div class="block justify-between mt-1 w-full">
        <div class="px-3 mt-1 w-full" v-if="editing">
          <div class="flex w-full">
            <t-input-group v-if="editing" :feedback="
              showValidation && errors.first('username') ? errors.first('username') : ''
            " :variant="showValidation && errors.first('username') ? 'danger' : ''"
              :class="checkingUsername ? 'w-11/12' : 'w-full'">
              <t-input v-validate="'required|username'" data-vv-validate-on="input" classes="mb-4 bg-transparent"
                v-model="user.username" @blur="debouncedInput" name="username" :placeholder="$i18n.t('username')"
                autocomplete="off" :disabled="isLoading"
                :variant="showValidation && errors.first('username') ? 'danger' : ''" />
            </t-input-group>
            <icon v-if="checkingUsername" class="text-xl mt-3 mx-auto"
              :class="validUsername ? 'text-green-600' : 'text-red-600'"
              :icon="validUsername ? 'check-circle' : 'times-circle'" />
          </div>

          <div class="flex">
            <t-input-group v-if="editing" :feedback="
              showValidation && errors.first('first_name')
                ? errors.first('first_name')
                : ''
            " :variant="showValidation && errors.first('first_name') ? 'danger' : ''" class="md:w-1/2 md:mr-2 mb-2">
              <t-input v-validate="'required|min:3|max:40'" data-vv-validate-on="blur" classes="bg-transparent"
                v-model="user.first_name" name="first_name" :placeholder="$i18n.t('name')" autocomplete="off"
                :disabled="isLoading" type="text"
                :variant="showValidation && errors.first('first_name') ? 'danger' : ''" />
            </t-input-group>

            <t-input-group v-if="editing" :feedback="
              showValidation && errors.first('last_name')
                ? errors.first('last_name')
                : ''
            " :variant="showValidation && errors.first('last_name') ? 'danger' : ''" class="md:w-1/2 md:mr-2 mb-2">
              <t-input v-validate="'required|min:3|max:40'" data-vv-validate-on="blur" classes="bg-transparent"
                v-model="user.last_name" name="last_name" :placeholder="$i18n.t('last_name')" autocomplete="off"
                :disabled="isLoading" type="text"
                :variant="showValidation && errors.first('last_name') ? 'danger' : ''" />
            </t-input-group>
          </div>

          <t-input-group v-if="editing" :feedback="
            showValidation && errors.first('biography') ? errors.first('biography') : ''
          " :variant="showValidation && errors.first('biography') ? 'danger' : ''" class="w-full md:mr-2 mb-2">
            <t-textarea v-validate="'required|min:3|max:400'" data-vv-validate-on="blur" classes="bg-transparent"
              v-model="user.biography" name="biography" :placeholder="$i18n.t('biography')" autocomplete="off"
              :disabled="isLoading" type="text"
              :variant="showValidation && errors.first('biography') ? 'danger' : ''" />
          </t-input-group>

          <div class="flex">
            <t-input-group v-if="editing" :feedback="
              showValidation && errors.first('link') ? errors.first('link') : ''
            " :variant="showValidation && errors.first('link') ? 'danger' : ''" class="md:w-1/2 md:mr-2 mb-2">
              <t-input v-validate="'url'" data-vv-validate-on="blur" classes="bg-transparent" v-model="user.link"
                name="link" :placeholder="$i18n.t('link')" autocomplete="off" :disabled="isLoading" type="url"
                :variant="showValidation && errors.first('link') ? 'danger' : ''" />
            </t-input-group>

            <t-input-group v-if="editing" class="flex w-full px-2 h-full md:w-1/2 md:mr-2 mb-2">
              <span class="mr-3 pb-2 text-black dark:text-white text-center">
                {{ $i18n.t("private_profile") }}
              </span>

              <t-toggle class="mt-2" :checked="user.private == 1" @change="handlePrivateChange" :value="1"
                :uncheckedValue="0" />
            </t-input-group>
          </div>

          <div class="flex justify-end">
            <t-button @click="editUser" class="mb-2" style="height: fit-content">
              <span v-if="!isLoading" class="text-white">{{ $i18n.t("save") }}</span>

              <span v-if="isLoading">
                <t-loader v-if="isLoading" extraClass="mx-auto my-auto border-orange-primary" /></span></t-button>
          </div>
        </div>

        <div class="px-2 md:px-5 w-full" v-else @click="clickToProfile ? $emit('click') : null">
          <div class="flex items-center">
            <div class="text-black dark:text-white mr-2" :style="smallFont ? 'font-size: ' + smallFontSize : ''"
              style="display: flex; align-items: center">
              <div class="flex justify-between items-center">
                <strong>{{ user.username }}</strong>
              </div>
              <span class="ml-1" v-if="user.verified == 1">
                <img src="/static/user_verified.png" style="display: flex; height: 16px; width: 16px" />
                <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
              </span>
            </div>

            <font-awesome-icon v-if="user.private == 1 && loggedUser && user.id == loggedUser.id"
              :style="{ 'font-size': smallFontSize }" :icon="['fas', 'user-lock']" />

            <button v-if="loggedUser && loggedUser.id !== user.id && user.followed_by_you_status === 2"
              id="notifications-menu" :class="user.subscribed === 1 ? 'bg-primary-700' : ''"
              class="mr-4 h-8 w-8 flex items-center justify-center transition duration-75 ease-in-out rounded-full text-black dark:text-white text-xl focus:outline-none focus:shadow-solid"
              :aria-label="$i18n.t('notifications')" @click="showModal = true">
              <span class="text-white">
                <img src="/static/icons/bell.svg" alt="Bell icon" width="20px" style="filter: brightness(0%) invert(1)"
                  class="dark:filter invert" />
              </span>
            </button>
          </div>
          <template v-if="!limitBiography">
            <p class="text-black dark:text-white" style="white-space: pre-line"
              :style="smallFont ? 'font-size: ' + smallFontSize : ''">
              {{ user.biography }}
            </p>
          </template>
          <template v-else>
            <p class="text-black dark:text-white" style="white-space: pre-line"
              :style="smallFont ? 'font-size: ' + smallFontSize : ''">
              {{
                  user.biography && user.biography.length > 40
                    ? user.biography.slice(0, 40) + "..."
                    : user.biography
              }}
            </p>
          </template>

          <a @click="openLink(user.link)" class="text-primary-800" href="#"
            style="text-overflow: ellipsis; word-wrap: break-word"
            :style="smallFont ? 'font-size: ' + smallFontSize : ''">{{ user.link }}</a>
        </div>

        <div class="w-full pb-2 flex justify-end items-end" v-if="!canEdit">
          <div v-if="allowFollow && loggedUser">
            <t-button variant="empty" @click="user.blocked === 0 ? toggleFollow() : showCannotFollow()" class="mr-2"
              :class="followButtonClasses(user)" style="height: fit-content">
              <span :class="followTextClasses" v-if="!followLoader">{{ user.followed_by_you_status === 0 ?
                  $i18n.t("follow") : ""
              }}{{ user.followed_by_you_status === 1 ? $i18n.t("pending") : ""
}}{{ user.followed_by_you_status === 2 ? $i18n.t("stop_following") : ""
}}{{ user.followed_by_you_status === 3 ? $i18n.t("rejected") : "" }}</span>
              <span v-if="followLoader">
                <t-loader extraClass="border-orange-primary mt-1" /></span>
            </t-button>
          </div>

          <template v-if="$route.path.includes('/profile') || $route.path.includes('/user')">
            <t-dropdown>
              <div slot="trigger" slot-scope="{
                  mousedownHandler,
                  focusHandler,
                  blurHandler,
                  keydownHandler,
                }" class="flex">
                <button
                  class="flex items-center justify-center flex-shrink-0 px-3 py-1 text-black dark:text-white bg-transparent rounded-r"
                  @mousedown="mousedownHandler" @focus="focusHandler" @blur="blurHandler" @keydown="keydownHandler">
                  <icon icon="ellipsis-v" class="mr-2 my-auto text-black dark:text-white text-xl sm:text-2xl"
                    style="cursor: pointer"></icon>
                </button>
              </div>

              <div slot-scope="{ blurHandler }">
                <button v-if="loggedUser && user.blocked === 1 && $route.path.includes('/user')"
                  class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                  role="menuitem" @blur="blurHandler" @click="openBlockModal">
                  {{ $i18n.t("unblock") }}
                </button>
                <button v-else-if="loggedUser && user.blocked === 0 && $route.path.includes('/user')"
                  class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                  role="menuitem" @blur="blurHandler" @click="openBlockModal">
                  {{ $i18n.t("block") }}
                </button>
                <button v-if="loggedUser && $route.path.includes('/user')"
                  class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                  role="menuitem" @blur="blurHandler" @click="reportUserModal = true">
                  {{ $i18n.t("report_user") }}
                </button>
                <button
                  class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                  role="menuitem" @blur="blurHandler" @click="copyProfileLink">
                  {{ $i18n.t("copy_profile_link") }}
                </button>
                <button
                  class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                  role="menuitem" @blur="blurHandler" @click="blurHandler">
                  {{ $i18n.t("cancel") }}
                </button>
              </div>
            </t-dropdown>
          </template>
        </div>

        <div v-if="!editing && canEdit" class="w-full flex justify-between items-end mt-2 pb-2 px-5">
          <t-button v-if="verifyUser" class="flex items-start" @click="
  verifyUserModal = true;
verification = {};
          ">
            {{ $i18n.t("verify_user") }}
            <img src="/static/user_verified.png" style="
                 {
                  display: flex;
                  height: 16px;
                  width: 16px;
                }
              " />
          </t-button>
          <div v-else></div>

          <div class="flex items-center">
            <t-button @click="logout" variant="gray" class="mr-2" style="height: fit-content">{{ $i18n.t("logout")
            }}</t-button>
            <t-button @click="editData" class="mr-2" style="height: fit-content">
              {{ $i18n.t("edit") }}
              <!--<img src="/static/icons/edit.svg" alt="Add image icon" class="ml-1 dark:filter invert w-5 mx-auto" />-->
            </t-button>

            <template v-if="$route.path.includes('/profile') || $route.path.includes('/user')">
              <t-dropdown>
                <div slot="trigger" slot-scope="{
                    mousedownHandler,
                    focusHandler,
                    blurHandler,
                    keydownHandler,
                  }" class="flex">
                  <button
                    class="flex items-center justify-center flex-shrink-0 pl-3 py-1 text-black dark:text-white bg-transparent rounded-r"
                    @mousedown="mousedownHandler" @focus="focusHandler" @blur="blurHandler" @keydown="keydownHandler">
                    <icon icon="ellipsis-v" class="my-auto text-black dark:text-white text-xl sm:text-2xl"
                      style="cursor: pointer"></icon>
                  </button>
                </div>

                <div slot-scope="{ blurHandler }">
                  <button v-if="loggedUser && user.blocked === 1 && $route.path.includes('/user')"
                    class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                    role="menuitem" @blur="blurHandler" @click="openBlockModal">
                    {{ $i18n.t("unblock") }}
                  </button>
                  <button v-else-if="loggedUser && user.blocked === 0 && $route.path.includes('/user')"
                    class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                    role="menuitem" @blur="blurHandler" @click="openBlockModal">
                    {{ $i18n.t("block") }}
                  </button>
                  <button v-if="loggedUser && $route.path.includes('/user')"
                    class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                    role="menuitem" @blur="blurHandler" @click="reportUserModal = true">
                    {{ $i18n.t("report_user") }}
                  </button>
                  <button
                    class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                    role="menuitem" @blur="blurHandler" @click="copyProfileLink">
                    {{ $i18n.t("copy_profile_link") }}
                  </button>
                  <button
                    class="block w-full px-1 py-1 text-sm leading-5 text-black dark:text-white transition duration-150 ease-in-out bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:focus:bg-gray-800"
                    role="menuitem" @blur="blurHandler" @click="blurHandler">
                    {{ $i18n.t("cancel") }}
                  </button>
                </div>
              </t-dropdown>
            </template>
          </div>
        </div>
      </div>
    </t-card>
    <t-modal :header="
      user.subscribed === 0
        ? $i18n.t('notification_subscribe_title')
        : $i18n.t('notification_unsubscribe_title')
    " :value="showModal" @closed="onClosed" variant="xl" :clickToClose="false" @before-close="beforeClose">
      <p class="text-black dark:text-white text-md md:text-md">
        {{
            user.subscribed === 0
              ? $i18n.t("notification_subscribe")
              : $i18n.t("notification_unsubscribe")
        }}
        <span class="font-black text-indigo-900 dark:text-indigo-300">{{
            user.username
        }}</span>
        {{
            user.subscribed === 0
              ? $i18n.t("notification_subscribe2")
              : $i18n.t("are_you_sure")
        }}
      </p>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button variant="gray-sm" class="mx-2" type="button" @click="onClosed()">{{
              $i18n.t("close")
          }}</t-button>
          <t-button class="mx-2" type="button" @click="subscribeUserNotification(user.subscribed)">{{ $i18n.t("accept")
          }}</t-button>
        </div>
      </template>
    </t-modal>
    <t-modal header="" :value="logoutConfirmationModal" @closed="onClosed" variant="md">
      <div class="p-3">
        <p class="mb-1 text-black dark:text-white">
          {{ $i18n.t("logout_confirmation") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" variant="gray" @click="logoutConfirmationModal = false" class="mr-2">
            {{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="logout()">{{ $i18n.t("logout") }}</t-button>
        </div>
      </template>
    </t-modal>

    <t-modal :header="$i18n.t(followType)" :value="followModal" @closed="closeFollowModal()" variant="lg">
      <div style="max-height: 50vh; overflow-y: auto">
        <div class="mx-auto text-center">
          <t-loader v-if="followListLoader" extraClass="border-orange-primary" />
        </div>
        <div class="my-auto" v-if="!followListLoader && followList.length === 0">
          <p class="text-black dark:text-white text-center">
            {{ $i18n.t("no_elements") }}
          </p>
        </div>
        <div class="my-1 px-3 w-full" v-for="(follow_user, index) in followList" :key="'follow-user-' + follow_user.id">
          <div class="w-full flex justify-between items-center">
            <div class="flex w-full cursor-pointer py-2">
              <div class="w-10 h-10" style="cursor: pointer" @click="$emit('openProfile', follow_user.user)">
                <div class="w-10 h-10" style="cursor: pointer" @click="openProfile(follow_user)">
                  <profile-picture :data="follow_user" :sizePx="128" />
                </div>
              </div>

              <div class="ml-3 my-auto" style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  cursor: pointer;
                  width: 100%;
                ">
                <Username :user="follow_user" />
                <div class="my-auto w-1/3">
                  <t-button v-if="loggedUser && follow_user.id !== loggedUser.id" variant="empty"
                    @click="followListUser(follow_user, index)" class="mx-auto my-auto"
                    :class="followButtonClasses(follow_user)" style="height: fit-content"
                    :disabled="listFollowLoading === index">
                    <span :class="followTextClasses">{{ follow_user.followed_by_you_status === 0 ? $i18n.t("follow") : ""
                    }}{{ follow_user.followed_by_you_status === 1 ? $i18n.t("pending") : ""
                    }}{{
                        follow_user.followed_by_you_status === 2
                          ? $i18n.t("stop_following")
                          : ""
                    }}{{
                        follow_user.followed_by_you_status === 3 ? $i18n.t("rejected") : ""
                    }}</span>
                  </t-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </t-modal>

    <t-modal header="" :value="reportUserModal" @closed="onClosed" variant="lg">
      <div style="max-height: 50vh; overflow-y: auto">
        <template v-if="reportStep === 1">
          <div class="my-auto" v-if="userReportCategories.length === 0">
            <p class="text-black dark:text-white text-center">
              {{ $i18n.t("no_elements") }}
            </p>
          </div>
          <div class="my-1 w-full py-3" v-for="category in userReportCategories" :key="'category-' + category.id"
            style="border-bottom: 1px solid #4a4a4a; cursor: pointer" @click="filterReasons(category)">
            <div class="w-full flex justify-between items-center">
              <div class="ml-2">
                <p class="text-black dark:text-white text-sm">
                  {{ category[$i18n.locale + "_name"] }}
                </p>
              </div>
              <icon icon="chevron-right" class="text-primary-1100 text-2xl" />
            </div>
          </div>
        </template>
        <template v-if="reportStep === 2">
          <div class="flex justify-start pt-2 pb-2">
            <t-button type="button" @click="reportStep = 1" style="height: fit-content">
              <icon class="mr-2" icon="angle-left" />{{ $i18n.t("return") }}
            </t-button>
          </div>
          <div class="my-auto" v-if="reportReasons.length === 0">
            <p class="text-black dark:text-white text-center">
              {{ $i18n.t("no_elements") }}
            </p>
          </div>
          <div class="my-0 w-full py-1" v-for="reason in reportReasons" :key="'reason-' + reason.id"
            style="border-bottom: 1px solid #4a4a4a; cursor: pointer">
            <div class="flex my-0 flex-wrap flex-col md:flex-row">
              <label class="flex justify-between items-center mx-1 my-1">
                <span class="text-black dark:text-white text-sm">{{
                    reason[$i18n.locale + "_name"]
                }}</span>
              </label>
              <t-radio class="ml-auto mr-2 jevi-radio-input" :value="reason.id" v-model="reportReason"
                :disabled="isReportLoading" />
            </div>
          </div>
          <t-button @click="reportUser" class="mt-2 mx-auto" style="height: fit-content" :disabled="!reportReason">
            <span v-if="!isLoading" class="text-white">{{ $i18n.t("report_user") }}</span>

            <span v-if="isReportLoading">
              <t-loader v-if="isReportLoading" extraClass="mx-auto my-auto border-orange-primary" /></span></t-button>
        </template>
      </div>
    </t-modal>

    <t-modal :header="$i18n.t('are_you_sure')" :value="userBlockConfirmation" @closed="onClosed" variant="md">
      <div class="p-3">
        <p v-if="user.blocked === 1" class="mb-1 text-black dark:text-white">
          {{ $i18n.t("user_unblock_confirmation") }}
        </p>
        <p v-else class="mb-1 text-black dark:text-white">
          {{ $i18n.t("user_block_confirmation") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" variant="gray" @click="userBlockConfirmation = false" class="mr-2">
            {{ $i18n.t("no") }}
          </t-button>
          <t-button v-if="user.blocked === 1" type="button" @click="blockUser">{{
              $i18n.t("unblock")
          }}</t-button>
          <t-button v-else type="button" @click="blockUser">{{
              $i18n.t("block")
          }}</t-button>
        </div>
      </template>
    </t-modal>
    <t-modal @closed="onClosed" :value="verifyUserModal" :header="$i18n.t('verify_user')" variant="xl">
      <template v-if="verificationStep === 1">
        <strong class="text-primary-900 my-2">{{ $i18n.t("select_verify") }}</strong>
        <div class="w-100 my-2">
          <t-select v-model="verification.reason" :placeholder="$i18n.t('select_verify')"
            :options="userVerifyReasonsOptions">
          </t-select>
        </div>
        <strong class="text-primary-900 my-2">{{ $i18n.t("text_area_verify") }}</strong>
        <t-textarea v-model="verification.description" class="my-2" name="reason" />
      </template>

      <template class="flex flex-col justify-center items-center" v-if="verificationStep === 2">
        <div>
          <strong class="text-primary-900 mt-2 mb-3">{{
              $i18n.t("verification_description_photos")
          }}</strong>
        </div>

        <div v-if="!activeCamera">
          {{ formatLang(userRandomPhotos[0]) }}
          <t-button class="mx-auto my-1" @click="
  activeCamera = true;
init();
          ">{{ $i18n.t("active_camera") }}</t-button>
        </div>
        <div v-else>
          <p v-if="permissionDenied" class="text-center mb-2">
            <strong>{{ $i18n.t("camera_permissions_browser") }}</strong>
          </p>

          {{ formatLang(userRandomPhotos[0]) }}

          <div class="flex justify-center">
            <div class="mb-2">
              <t-button class="mx-auto" id="snap1" variant="gray">
                <span v-if="pictureTaken" class="text-white">{{
                    $i18n.t("new_photo")
                }}</span>
                <span v-else class="text-white">{{ $i18n.t("take_photo") }}</span>
              </t-button>
            </div>
          </div>
          <div class="w-full px-2">
            <div class="w-full my-2 md:my-0">
              <div class="mx-auto video-wrapper">
                <video class="mx-auto" id="video1" playsinline :width="400" :height="400" autoplay></video>
                <canvas id="canvas1" class="mx-auto hidden" :width="400" :height="400"></canvas>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template class="flex flex-col justify-center items-center" v-if="verificationStep === 3">
        <div>
          <strong class="text-primary-900 mt-2 mb-3">{{
              $i18n.t("verification_description_photos")
          }}</strong>
        </div>

        <div v-if="!activeCamera">
          {{ formatLang(userRandomPhotos[1]) }}
          <t-button class="mx-auto my-1" @click="
  activeCamera = true;
init();
          ">{{ $i18n.t("active_camera") }}</t-button>
        </div>
        <div v-else>
          <p v-if="permissionDenied" class="text-center mb-2">
            <strong>{{ $i18n.t("camera_permissions_browser") }}</strong>
          </p>

          {{ formatLang(userRandomPhotos[1]) }}

          <div class="flex justify-center">
            <div class="mb-2">
              <t-button class="mx-auto" id="snap2" variant="gray">
                <span v-if="pictureTaken" class="text-white">{{
                    $i18n.t("new_photo")
                }}</span>
                <span v-else class="text-white">{{ $i18n.t("take_photo") }}</span>
              </t-button>
            </div>
          </div>
          <div class="w-full px-2">
            <div class="w-full my-2 md:my-0">
              <div class="mx-auto video-wrapper">
                <video class="mx-auto" id="video2" playsinline :width="400" :height="400" autoplay></video>
                <canvas id="canvas2" class="mx-auto hidden" :width="400" :height="400"></canvas>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="verificationStep === 4">
        <div class="w-100 text-center my-2">
          <strong class="text-primary-900 my-2">{{ $i18n.t("confirmation") }}</strong>
        </div>
        <div class="w-100 text-center my-2">
          {{ $t("confirm_verification") }}
        </div>
      </template>

      <template v-slot:footer>
        <div class="flex justify-between">
          <template v-if="verificationStep == 1">
            <t-button type="button" @click="verifyUserModal = false" variant="gray">
              {{ $i18n.t("cancel") }}
            </t-button>
          </template>
          <template v-else-if="verificationStep == 2">
            <div></div>
            <!--<t-button type="button" @click="verificationStep = 1; verification.url1 = null; verification.photo1 = null; activeCamera = false; pictureTaken = false;">
              {{ $i18n.t("back") }}
            </t-button>-->
          </template>
          <template v-else-if="verificationStep == 3">
            <t-button type="button" variant="gray" @click="
  verificationStep = 2;
verification.url2 = null;
verification.photo2 = null;
activeCamera = false;
pictureTaken = false;
            ">
              {{ $i18n.t("back") }}
            </t-button>
          </template>
          <template v-else>
            <t-button type="button" variant="gray" @click="
  verificationStep = 3;
verification.url2 = null;
verification.photo2 = null;
activeCamera = false;
pictureTaken = false;
            ">
              {{ $i18n.t("back") }}
            </t-button>
          </template>
          <template v-if="verificationStep === 1">
            <t-button :disabled="disableVerificationStep1" @click="
  verificationStep = 2;
activeCamera = false;
pictureTaken = false;
            " type="button">
              {{ $i18n.t("next") }}
            </t-button>
          </template>
          <template v-else-if="verificationStep === 2">
            <t-button :disabled="!pictureTaken" @click="
  saveVerificationPhoto();
verificationStep = 3;
activeCamera = false;
pictureTaken = false;
            " type="button">
              {{ $i18n.t("next") }}
            </t-button>
          </template>
          <template v-else-if="verificationStep === 3">
            <t-button :disabled="!pictureTaken" type="button" @click="
  saveVerificationPhoto();
cameraOff();
verificationStep = 4;
activeCamera = false;
pictureTaken = false;
            ">
              {{ $i18n.t("next") }}
            </t-button>
          </template>
          <template v-else>
            <t-button type="button" @click="submitVerification()" style="height: fit-content">
              <span v-if="!isLoadingVerification" class="text-white">
                {{ $i18n.t("send") }}
              </span>
              <span v-if="isLoadingVerification">
                <t-loader v-if="isLoadingVerification" extraClass="mx-auto my-auto border-orange-primary" />
              </span>
            </t-button>
          </template>
        </div>
      </template>
    </t-modal>

    <t-modal :header="selectedTypePhoto" :value="cameraModal" @closed="closeModal('cameraModal')" variant="xl">
      <!--<p v-if="!permissionDenied" class="text-md text-black md:text-lg">
        {{ $i18n.t("camera_permissions") }}
      </p>-->
      <p v-if="permissionDenied" class="text-center mb-2">
        <strong>{{ $i18n.t("camera_permissions_browser") }}</strong>
      </p>

      <div class="flex justify-center">
        <div class="mb-2">
          <t-button class="mx-auto" id="snap" variant="gray">
            <span v-if="pictureTaken" class="text-white">{{ $i18n.t("new_photo") }}</span>
            <span v-else class="text-white">{{ $i18n.t("take_photo") }}</span>
          </t-button>
        </div>
      </div>
      <div class="w-full px-2">
        <div class="w-full my-2 md:my-0">
          <div class="mx-auto video-wrapper">
            <video class="mx-auto" id="video" playsinline :width="400" :height="400" autoplay></video>
            <canvas id="canvas" class="mx-auto hidden" style="max-width: 400px; max-height: 400px"></canvas>
          </div>
        </div>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" variant="outline-danger" @click="closeModal('cameraModal')" class="mr-2">
            Cerrar
          </t-button>
          <t-button type="button" :disabled="!pictureNotBlank || !pictureTaken" @click="saveVerificationPhoto">{{
              $i18n.t("accept")
          }}</t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
import ProfilePicture from "@/components/user/ProfilePicture.vue";
import Username from "@/components/user/Username.vue"
const notify = () => import("@/utils/notify.js");
import { debounce } from "lodash";

export default {
  components: {
    ProfilePicture,
    Username,
  },
  props: {
    user: {
      type: Object,
      default: {},
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canView: {
      type: Boolean,
      default: true,
    },
    allowFollow: {
      type: Boolean,
      default: true,
    },
    showFollowModals: {
      type: Boolean,
      default: true,
    },
    cardWidth: {
      type: String,
      default: "w-2/3",
    },
    cardOffset: {
      type: String,
      default: "-mt-16",
    },
    profilePictureSize: {
      type: String,
      default: "32",
    },
    limitBiography: {
      type: Boolean,
      default: false,
    },
    smallFont: {
      type: Boolean,
      default: false,
    },
    clickToProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editing: false,
      stream: undefined,
      isLoadingVerification: false,
      activeCamera: false,
      cameraModal: false,
      selectedTypePhoto: "",
      permissionDenied: false,
      pictureTaken: false,
      pictureNotBlank: false,
      showModal: false,
      showValidation: false,
      isLoading: false,
      dataEdited: false,
      logoutConfirmationModal: false,
      followLoader: false,
      followModal: false,
      followListLoader: false,
      listFollowLoading: false,
      followType: "",
      followList: [],
      reportUserModal: false,
      verifyUserModal: false,
      reportStep: 1,
      verificationStep: 1,
      reportReasons: [],
      verification: {},
      reportReason: null,
      isReportLoading: false,
      userBlockConfirmation: false,
      validUsername: false,
      checkingUsername: false,
      lastCheckedUsername: "",
      private_status: null,
      base64Img: null,
    };
  },
  computed: {
    randomPicture() {
      if (this.data["imgkit_random_picture"]) {
        return this.data["imgkit_random_picture"] + `?tr=w-64,h-64`;
      } else return this.data[this.fieldName];
    },
    verifyUser() {
      if (
        this.loggedUser && this.user.id === this.loggedUser.id &&
        this.user.followers > 1 &&
        this.user.verified == 0 &&
        this.user.verification_request == 0
      ) {
        return true;
      }
    },
    smallFontSize() {
      return "0.80rem";
    },
    disableVerificationStep1() {
      if (!this.verification.description || !this.verification.reason) {
        return true;
      } else {
        return false;
      }
    },
    disableVerificationStep2() {
      if (!this.verification.photo1 || !this.verification.url1) {
        return true;
      } else {
        return false;
      }
    },
    disableVerificationStep3() {
      if (!this.verification.photo2 || !this.verification.url2) {
        return true;
      } else {
        return false;
      }
    },
    followTextClasses() {
      if (!this.user) {
        return "text-sm hover:text-white";
      }
      if ([0, 2, 3].includes(this.user.followed_by_you_status))
        return "text-sm text-white";
      if (this.user.followed_by_you_status === 1) return "text-sm text-white";
    },
    theme() {
      return this.$store.getters["getTheme"];
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"];
    },
    userReportCategories() {
      return this.$store.getters["userReportCategory/getAllUnpaginated"];
    },
    userReportReasons() {
      return this.$store.getters["reportReason/getAllUnpaginated"].filter(
        (x) => x.type_report === 1
      );
    },
    userVerifyReasons() {
      return this.$store.getters["verification/getAllUnpaginated"];
    },
    userVerifyReasonsOptions() {
      return this.userVerifyReasons.map((verify) => ({
        value: verify.id,
        text: this.formatLang(verify),
      }));
    },
    userRandomPhotos() {
      return this.$store.getters["random_photo/getAllUnpaginated"];
    },
    usernameCheck() {
      return this.$store.getters["getUsernameCheck"];
    },
    formatLang() {
      return (payload) => {
        const lang = this.$i18n.locale;
        return lang === "es" ? payload.es_name : payload.en_name;
      };
    },
  },
  methods: {
    change({ canvas }) {
      canvas.toBlob((blob) => {
        const file = new File([blob], "image.png");
        this.profile_picture = file;
      });
    },
    saveVerificationPhoto() {
      let step = String(this.verificationStep - 1);
      console.log(step);

      const canvas = document.getElementById("canvas" + step);
      //.toDataURL("image/jpeg")
      //.replace("image/jpeg", "image/octet-stream");

      canvas.toBlob((blob) => {
        const file = new File([blob], "image.png");

        if (step == 1) {
          this.verification.url1 = file;
          this.verification.photo1 = file;

          console.log("VERIFICATION PHOTO 1: ", this.verification.photo1);
          console.log("VERIFICATION URL 1: ", this.verification.url1);
        } else {
          this.verification.url2 = file;
          this.verification.photo2 = file;

          console.log("VERIFICATION PHOTO 2: ", this.verification.photo2);
          console.log("VERIFICATION URL 2: ", this.verification.url2);
        }
      });
    },
    async init() {
      this.cameraOff();

      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            width: 400,
            height: 400,
            facingMode: "user",
          },
        });
        this.handleSuccessPhoto(this.stream);
      } catch (e) {
        console.log(`navigator.getUserMedia error:${e.toString()}`);
        if (e.toString().toLowerCase().includes("permission denied"))
          this.permissionDenied = true;
      }
    },
    async cameraOff() {
      if (this.stream) {
        let tracks = this.stream.getTracks();

        tracks.forEach((track) => {
          track.stop();
        });
      }
      console.log("Camera Off");
    },
    handleSuccessPhoto(stream) {
      const vue = this;
      window.stream = stream;
      let step = String(this.verificationStep - 1);
      console.log(step);

      document.getElementById("video" + step).srcObject = stream;
      var context = document.getElementById("canvas" + step).getContext("2d");

      document.getElementById("snap" + step).addEventListener("click", function () {
        if (document.getElementById("canvas" + step).classList.contains("hidden")) {
          const video = document.getElementById("video" + step);
          video.classList.add("hidden");
          document.getElementById("canvas" + step).classList.remove("hidden");
          vue.pictureTaken = true;
        } else {
          document.getElementById("canvas" + step).classList.add("hidden");
          document.getElementById("video" + step).classList.remove("hidden");
          vue.pictureTaken = false;
          vue.pictureNotBlank = false;
        }

        let size = 400;

        context.drawImage(document.getElementById("video" + step), 0, 0, size, size);
        vue.pictureNotBlank = context
          .getImageData(0, 0, size, size)
          .data.some((channel) => channel !== 0);
      });
    },
    closeModal(modal) {
      this[modal] = false;
      this.cameraOff();
    },
    submitVerification() {
      this.isLoadingVerification = true;
      this.cameraOff();

      console.log(this.verification);

      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.user.id,
            id_verification_reason: this.verification.reason,
            description: this.verification.description,
            id_type_photo_1: this.userRandomPhotos[0] ? this.userRandomPhotos[0].id : 0,
            id_type_photo_2: this.userRandomPhotos[1] ? this.userRandomPhotos[1].id : 0,
            url_verification_photo_1: this.verification.url1,
            url_verification_photo_2: this.verification.url2,
          },
          route: "/user_verification/save",
          module: null,
        })
        .then((response) => {
          this.isLoadingVerification = false;

          notify().then(({ notification }) => {
            notification(
              "verification_user",
              response.code,
              this.$snotify,
              this.$i18n.locale
            );
          });

          if (response.code == 200) {
            this.user.verification_request = 1;
            this.onClosed();
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoadingVerification = false;
          notify().then(({ notification }) => {
            notification("error", 500, this.$snotify, this.$i18n.locale);
          });
        });
    },
    handlePrivateChange(data) {
      if (this.loggedUser && data == this.loggedUser.private) {
        this.private_status = null;
        this.dataEdited = false;
      } else {
        this.private_status = String(data);
        this.dataEdited = true;
      }
    },

    checkUsername() {
      if (this.user.username !== "") {
        const blank_space = String(this.user.username).indexOf(" ");
        if (blank_space >= 0)
          return this.$snotify.error(
            this.$i18n.locale === "en"
              ? "Username cannot have empty spaces"
              : "El nombre de usuario no puede tener espacios en blanco",
            "Error",
            {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom",
            }
          );
      }
      if (!/^[a-z._0-9]+$/i.test(this.user.username)) {
        this.checkingUsername = true;
        this.$snotify.error(
          this.$i18n.locale === "en"
            ? "Only . and _ symbols are allowed"
            : "Solo se permiten los símbolos . y _",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom",
          }
        );
        this.validUsername = false;

        return;
      } else {
        this.checkingUsername = true;
      }

      if (this.user.username !== this.lastCheckedUsername) {
        if (this.user.username !== "")
          this.$socket.emit("check_username", {
            username: this.user.username,
          });
        setTimeout(() => {
          if (this.user.username !== "") {
            this.checkingUsername = true;
          } else {
            this.checkingUsername = false;
          }
        }, 250);
      }

      this.lastCheckedUsername = this.user.username;
    },
    debouncedInput() { },

    followButtonClasses(user) {
      if (!user) {
        return "px-2 py-1 bg-transparent border border-primary-500 rounded shadow-sm hover:bg-primary-1200";
      }

      if (user.followed_by_you_status === 0)
        return "px-2 py-1 bg-primary-1100 border border-primary-500 rounded shadow-sm hover:bg-primary-1200";
      else if (user.followed_by_you_status === 1)
        return "px-2 py-1 bg-transparent border border-gray-500 rounded shadow-sm hover:bg-gray-500";
      else if (user.followed_by_you_status === 2)
        return "px-2 py-1 bg-red-500 border border-red-500 rounded shadow-sm hover:bg-red-500";
      else
        return "px-2 py-1 bg-primary-1100 border border-primary-500 rounded shadow-sm hover:bg-primary-1200";
    },
    subscribeUserNotification(subscribed) {
      this.$store
        .dispatch("global/create", {
          route: `/user/${this.user.id}/subscribe`,
          module: null,
          payload: {
            id_user: this.loggedUser.id,
            id_user_to_subscribe: this.user.id,
          },
        })
        .then((response) => {
          let user = this.user;

          if (this.user.followed_by_you_status !== 2) {
            user.subscribed = 0;
            this.$emit("userUpdate", user);
            return;
          }

          subscribed === 1
            ? (user.subscribed = subscribed - 1)
            : (user.subscribed = subscribed + 1);

          this.$socket.emit("join_topics", {
            id_user: this.loggedUser.id,
          });

          this.$emit("userUpdate", user);

          this.loader = false;
          this.onClosed();

          notify().then(({ notification }) => {
            notification(
              subscribed === 1 ? "unsubscribeUser" : "subscribeUser",
              response.code,
              this.$snotify,
              this.$i18n.locale
            );
          });
        })
        .catch((error) => {
          this.loader = false;
          this.onClosed();
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale);
          });
        });
    },
    openStory(data) {
      this.$emit("openStory", data);
    },
    openBlockModal() {
      if (this.user.id_role === 1) {
        this.$snotify.error(
          this.$i18n.locale === "en"
            ? "You cannot block this user"
            : "No puedes bloquear a este usuario",
          this.$i18n.locale === "en" ? "You cannot do that" : "No puedes hacer eso",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom",
          }
        );
        return;
      }
      this.userBlockConfirmation = true;
    },
    copyProfileLink() {
      let link = 'https://' + location.hostname + '/share/' + this.user.username
      console.log(link)

      navigator.clipboard.writeText(link);

      notify().then(({ notification }) => {
        notification("text_copied", 200, this.$snotify, this.$i18n.locale);
      });
    },
    showCannotFollow() {
      return this.$snotify.error(
        this.$i18n.locale === "en"
          ? "You cannot follow a blocked user"
          : "No puedes seguir a un usuario bloqueado",
        "Error",
        {
          timeout: 5000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          titleMaxLength: 40,
          bodyMaxLength: 100,
          position: "rightBottom",
        }
      );
    },
    blockUser() {
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            id_user_blocked: this.user.id,
          },
          route: "/user_blocked/save",
          module: null,
        })
        .then((response) => {
          if (response.data)
            this.$emit("userUpdate", {
              ...this.user,
              blocked: response.data.active === 1 ? 1 : 0,
            });
          if (response.code === 200) {
            if (response.data.active === 1) {
              notify().then(({ notification }) => {
                notification(
                  "block_user",
                  response.code,
                  this.$snotify,
                  this.$i18n.locale
                );
              });
            } else {
              notify().then(({ notification }) => {
                notification(
                  "unblock_user",
                  response.code,
                  this.$snotify,
                  this.$i18n.locale
                );
              });
            }
          } else {
            notify().then(({ notification }) => {
              notification("block_user", response.code, this.$snotify, this.$i18n.locale);
            });
          }
          this.userBlockConfirmation = false;
        })
        .catch((error) => {
          console.log("block error:", error);
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale);
          });
        });
    },
    filterReasons(category) {
      this.reportReasons = this.userReportReasons.filter(
        (x) => x.id_user_report_category === category.id
      );
      this.reportStep = 2;
    },
    handleUserUpdate(data) {
      this.$emit("userUpdate", data);
    },
    calculateHighNumber(number) {
      if (isNaN(number)) return 0;
      if (Number(number) > 1000000) return (number / 1000000).toFixed(2) + "M";

      return number;
    },
    reportUser() {
      if (!this.reportReason) {
        this.$snotify.error(
          this.$i18n.locale === "en"
            ? "Select a report reason"
            : "Selecciona un motivo para el reporte",
          this.$i18n.locale === "en" ? "Error" : "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom",
          }
        );
      }
      this.isReportLoading = true;
      this.$store
        .dispatch("global/create", {
          payload: {
            id_reporting_reason: this.reportReason,
            id_user_reporting: this.loggedUser.id,
            id_user_reported: this.user.id,
          },
          route: "/user_reported/save",
          module: null,
        })
        .then((response) => {
          this.onClosed();
          this.isReportLoading = false;
          if (response.code === 409) {
            notify().then(({ notification }) => {
              notification(
                "report_user",
                response.code,
                this.$snotify,
                this.$i18n.locale
              );
            });
          } else {
            notify().then(({ notification }) => {
              notification("report", response.code, this.$snotify, this.$i18n.locale);
            });
          }
        })
        .catch((error) => {
          this.isReportLoading = false;
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale);
          });
        });
    },
    onClosed() {
      this.showModal = false;
      this.logoutConfirmationModal = false;
      this.reportUserModal = false;
      this.reportStep = 1;
      this.reportReason = null;
      this.userBlockConfirmation = false;
      this.verifyUserModal = false;
      this.stream = undefined;
      this.isLoadingVerification = false;
      this.activeCamera = false;
      this.pictureTaken = false;
      this.pictureNotBlank = false;
      this.verification = {};
      this.verificationStep = 1;
      this.cameraOff();
    },
    openProfile(user) {
      this.followModal = false;
      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.$router.push("/profile");
      } else {
        this.$router.push(`/user/${user.username}`);
      }
    },
    closeFollowModal() {
      this.followModal = false;
      this.followList = [];
    },
    openFollowModal(type) {
      this.followListLoader = true;
      this.followModal = true;
      let params = {
        order_key: "updated_at",
        order_value: "desc",
        status_request: "2",
        active: 1,
      };
      if (type === "following") params.id_user_sent = this.user.id;

      if (type === "followers") params.id_user_received = this.user.id;
      this.followType = type;
      this.$store
        .dispatch("global/getItems", {
          route: "/follow_request/all",
          module: null,
          noPaginate: true,
          params,
        })
        .then((response) => {
          if (type === "following")
            this.followList = response.data.map(
              ({ user_received, received_followed_by_you_status }) => ({
                ...user_received,
                followed_by_you_status: received_followed_by_you_status,
              })
            );

          if (type === "followers")
            this.followList = response.data.map(
              ({ user_sent, sent_followed_by_you_status }) => ({
                ...user_sent,
                followed_by_you_status: sent_followed_by_you_status,
              })
            );

          this.followListLoader = false;
        })
        .catch((error) => {
          this.followListLoader = false;
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale);
          });
        });
    },
    followListUser(user, index) {
      if (user.followed_by_you_status === 3) return;

      console.log(user);

      this.listFollowLoading = index;
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user_sent: this.loggedUser.id,
            id_user_received: user.id,
          },
          route: "/follow_request/save",
          module: null,
        })
        .then((response) => {

          let userx = user;
          let userData = this.user;
          if (response.data.active === 2) {
            userx.followed_by_you_status = 0;

            if (this.user.id === this.loggedUser.id) {
              userData.following--;

            }
          } else {
            userx.followed_by_you_status = response.data.status_request;
            if (this.user.id === this.loggedUser.id) {
              userData.following++;

            }
          }
          this.followList[index] = userx;
          this.listFollowLoading = null;
        })
        .catch((error) => {
          this.listFollowLoading = null;
          console.log("list follow error:", error);
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale);
          });
        });
    },
    toggleFollow() {
      if (this.user.followed_by_you_status === 3) return;
      this.followLoader = true;
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user_sent: this.loggedUser.id,
            id_user_received: this.user.id,
          },
          route: "/follow_request/save",
          module: null,
        })
        .then((response) => {
          this.followLoader = false;
          let user = this.user;
          if (response.data.active === 2) {
            this.subscribeUserNotification(1);
            user.followed_by_you_status = 0;
            user.followers--;
          } else {
            user.followed_by_you_status = response.data.status_request;
            user.followers++;
          }
          this.$emit("userUpdate", user);
        })
        .catch((error) => {
          console.log("follow error:", error);
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale);
          });
          this.followLoader = false;
        });
    },
    openLink(url) {
      const newWindow = window.open(`${url}`, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    },
    beforeClose() {
      if (this.$refs.profileImg) {
        this.$refs.profileImg.image = null;
        this.$refs.profileImg.$refs.file = null;
      }

      this.profile_picture = null;
      this.base64Img = null;
    },
    logout() {
      if (!this.logoutConfirmationModal) {
        this.logoutConfirmationModal = true;
        return;
      }
      this.$store
        .dispatch("auth/logout", this.$acl.change)
        .then((response) => {
          notify().then(({ notification }) => {
            this.$socket.emit("user_disconnected");
            notification("logout", 200, this.$snotify, this.$i18n.locale);
          });
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale);
          });
        });
    },
    editData() {
      this.editing = true;
    },
    editUser() {
      if (!this.dataEdited) {
        this.editing = false;
        return;
      }
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true;
        if (isValid) {
          this.isLoading = true;

          let payload = {
            id: this.user.id,
            username: this.user.username,
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            biography: this.user.biography,
            link: this.user.link ? this.user.link : " ",
            private: this.private_status,
          };

          if (!this.private_status) delete payload.private;

          this.$store
            .dispatch("global/update", {
              payload: payload,
              route: "/user/update",
              module: "user",
              isCurrentUser: true,
            })
            .then((response) => {
              this.isLoading = false;
              let userx = response.data;
              if (userx.link === " ") userx.link = "";
              this.$emit("userUpdate", userx);

              notify().then(({ notification }) => {
                notification(
                  "master-update",
                  response.code,
                  this.$snotify,
                  this.$i18n.locale
                );
              });
              this.editing = false;
              this.dataEdited = false;
            })
            .catch((error) => {
              this.isLoading = false;
              this.editing = false;
              this.dataEdited = false;
              notify().then(({ notification }) => {
                notification("error", error.code, this.$snotify, this.$i18n.locale);
              });
            });
        }
      });
    },
    getUserReportCategories() {
      this.$store
        .dispatch("global/getItems", {
          route: "/user_report_category/all",
          module: "userReportCategory",
          noPaginate: true,
          isMaster: true,
        })
        .then((response) => { })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale);
          });
        });
    },
    getUserVerificationReasons() {
      this.$store
        .dispatch("global/getItems", {
          payload: {
            active: 1,
            order_key: "es_name",
            order_value: "asc",
          },
          route: "/master/verification_reason/all",
          module: "verification",
          noPaginate: true,
        })
        .then((response) => { })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale);
          });
        });
    },
    getUserVerificationPhotos() {
      this.$store
        .dispatch("global/getItems", {
          payload: {
            active: 1,
            order_key: "es_name",
            order_value: "asc",
          },
          route: "/master/type_photo/get_random",
          module: "random_photo",
          noPaginate: true,
        })
        .then((response) => { })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale);
          });
        });
    },
  },
  created() {
    this.getUserReportCategories();
    this.getUserVerificationPhotos();

    this.getUserVerificationReasons();

    this.debouncedInput = debounce(this.checkUsername, 200);
  },
  watch: {
    user: {
      handler() {
        if (this.editing) {
          this.dataEdited = true;
        }
      },
      deep: true,
    },
    usernameCheck: {
      handler() {
        if (!this.usernameCheck.username_valid) {
          this.$snotify.error(
            this.$i18n.locale === "en"
              ? "Only lowercase letters are allowed"
              : "Solo se permiten letras minúsculas",
            "Error",
            {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom",
            }
          );
          this.validUsername = false;
        } else if (
          !this.usernameCheck.username_available &&
          this.loggedUser.username != this.usernameCheck.username
        ) {
          this.$snotify.error(
            this.$i18n.locale === "en"
              ? "This username is already in use"
              : "Este nombre de usuario ya está en uso",
            "Error",
            {
              timeout: 5000,
              showProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              titleMaxLength: 40,
              bodyMaxLength: 100,
              position: "rightBottom",
            }
          );
          this.validUsername = false;
        } else {
          this.validUsername = true;
        }
      },
      deep: true,
    },
  },
};
</script>
