<template>
  <div class="flex">
    <div v-if="loader">
      ...
    </div>
    <div v-else class="w-full">

      <div v-if="buttons" class="mb-5 px-5 md:px-3">
        <div class="mb-5 px-5 md:px-3">
          <carousel :nav="false" :dots="false" class="carousel-card video-carousel relative" :margin="15" :responsive="{
            0: { items: 1, nav: false },
            340: { items: 1, nav: false },
            650: { items: 2, nav: false },
            800: { items: 3, nav: false }
          }" style="background-color: transparent;">
            <video-preview v-for="video in originalPosts" :key="`video-${video.id}`" :element="video" :user="video.user"
              @clickVideo="openPost(video)" />

            <template slot="prev">
              <ArrowCarousel direction="left" />
            </template>
            <template slot="next">
              <ArrowCarousel direction="right" />
            </template>
          </carousel>
        </div>
      </div>
      <div v-if="!buttons" :key="'video-group-cuts-all'" class="mb-5 px-5 md:px-3">
        <p class="text-black dark:text-white text-md sm:text-lg md:text-2xl mb-3">
          {{ $i18n.t('content_creator') }}
        </p>
        <carousel :nav="false" style="max-height: 30vh; background-color: transparent;" :dots="false"
          class="carousel-card video-carousel relative" :margin="15" :responsive="{
            0: { items: 1, nav: false },
            340: { items: 2, nav: false },
            650: { items: 3, nav: false },
            800: { items: 3, nav: false }
          }">
          <video-preview v-for="video in originalPosts" :key="`video-${video.id}-home`" :moreInfo="buttons"
            :element="video" :user="video.user" @clickVideo="openPost(video)" />
          <template slot="prev">
            <ArrowCarousel direction="left" />
          </template>
          <template slot="next">
            <ArrowCarousel direction="right" />
          </template>
        </carousel>
      </div>
    </div>

  </div>
</template>

<script>
import carousel from "vue-owl-carousel"
import VideoPreview from "@/components/gallery/VideoPreview.vue"
import { debounce } from "lodash"
import ArrowCarousel from "../components/arrowCarousel.vue"
const notify = () => import("@/utils/notify.js")

export default {
  data() {
    return {
      activeHashtag: null,
      hashtags: [],
      loader: false,
      itemsAmount: Math.floor((window.screen.height * 0.75) / 100)
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    originalPosts() {
      if (!this.buttons) {
        return this.$store.getters["post/getContentCreators"]
      }
      if (!this.activeHashtag) {
        return this.$store.getters["post/getAllGrouped2"]
      } else {
        return this.$store.getters["post/getAllGrouped2"].filter(
          (x) => x.hashtag.id === this.activeHashtag
        )
      }
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    suggestedVideos() {
      return this.$store.getters["post/getAllSuggestedVideos"]
    },
    smallFontSize() {
      return "0.80rem"
    }
  },
  props: {
    postModal: {
      type: Boolean,
      default: false
    },
    buttons: {
      type: Boolean,
      default: true
    },
    openPostWithEmit: {
      type: Boolean,
      default: false
    },
    currentPost: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    carousel,
    VideoPreview,
    ArrowCarousel
  },
  methods: {
    onImageLoad(id) {
      if (this.$refs["loading-picture-" + id][0])
        this.$refs["loading-picture-" + id][0].classList.add("hidden")
      if (this.$refs["video-preview-" + id][0])
        this.$refs["video-preview-" + id][0].classList.remove("hidden")
    },
    filterByHashtag(hashtag) {
      if (this.activeHashtag === hashtag.id) {
        this.activeHashtag = null
      } else {
        this.activeHashtag = hashtag.id
      }
    },
    openPost(post) {
      if (this.openPostWithEmit) return this.$emit('openPost', post)
      this.$emit("update:currentPost", post)
      this.$emit("update:postModal", true)
      this.$socket.emit("post_viewed", {
        id_user: this.loggedUser.id,
        id_post: post.id
      })
    },
    getOriginalPosts() {
      this.loader = true
      if (this.buttons) return
      this.$store
        .dispatch("global/getItems", {
          route: "/post/content_creators",
          module: null,
          noPaginate: true,
          params: {
            type: 5,
            active: 1,
            page: 1,
            limit: 20
          }
        })
        .then((response) => {
          this.$store.dispatch("post/setContentCreators", response.data)
          this.loader = false
        })
        .catch((error) => {
          this.$emit("finishLoading")
          this.loader = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getOriginals() {
      if (!this.buttons) return
      this.loader = true
      this.$store
        .dispatch("global/getItems", {
          route: "/post/originals/all",
          module: null,
          noPaginate: true,
          params: {
            active: 1,
            order_key: 'updated_at',
            order_value: 'desc',
            page: 1,
            limit: 20,
            type: 5,
          }
        })
        .then((response) => {
          this.$store.dispatch("post/setGroupedElements2", response.data.data)
          this.$emit("finishLoading")
          this.loader = false
        })
        .catch((error) => {
          this.$emit("finishLoading")
          this.loader = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getSuggestedVideos() {
      this.$store
        .dispatch("global/getItems", {
          route: "/post/top_10/2/" + this.loggedUser.id,
          module: null,
          noPaginate: true,
          params: {
            active: 1
          }
        })
        .then((response) => {
          this.$store.dispatch("post/setSuggestedVideos", response.data)
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    debouncedResize() {
      this.itemsAmount = Math.floor((window.screen.height * 0.75) / 100)
    }
  },
  created() {
    this.$emit("startLoading")
    this.getOriginalPosts()
    this.getOriginals()
    this.getSuggestedVideos()
    window.addEventListener("resize", debounce(this.debouncedResize, 500))
  },
  beforeDestroy() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },
  unmounted() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },

  watch: {}
}
</script>
<style>
.carousel-card .owl-nav {
  display: none;
}

.video-carousel .owl-carousel .owl-item {
  width: 274px !important;
}

/*
.active-hashtag {
  @apply bg-gray-500 dark: bg-gray-400;
}
*/
</style>
