<template>
  <div class="my-0 flex flex-col justify-between"
    :style="element.type == 2 ? 'max-height: 350px' : 'max-height: 600px'">
    <div v-if="moreInfo" class="pb-2 px-3" style="height: 60px">
      <div class="w-full flex justify-between" style="align-items: center;">
        <div class="flex">
          <div class="w-14 h-14">
            <profile-picture :data="user" :sizePx="128" />
          </div>

          <div class="ml-3 my-auto">
            <p class="text-black dark:text-white text-sm font-bold" @click="
              loggedUser.id === user.id
                ? $router.push('/profile')
                : $router.push(`/user/${user.username}`)
            " style="display: flex; align-items: center; cursor: pointer">
              {{ user.username }}
              <span class="ml-1" v-if="user.verified == 1">
                <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
                <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
              </span>
            </p>
            <p class="text-gray-400 text-sm">
              <small>{{ element.created_at | fromNow }}</small>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="relative" style="cursor: pointer;"
      :style="element.type == 2 ? 'height: 150px' : (moreInfo ? 'height: 400px' : 'height: 200px')"
      @click="$emit('clickVideo', element)">
      <icon v-if="element.type === 2" icon="play" class="text-white text-2xl" style="
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -10.5px;
          margin-top: -12px;
        "></icon>
      <video v-if="element.type === 5" class="mx-auto px-2 py-1" :src="element.imgkit_url_video || element.url_video"
        style="width: 100%; height: 100%;" @play="(evt) => handleVideoPlay(element.id, evt.target)" playsinline autoplay
        controls muted controlsList="nodownload" loop></video>
      <img v-else :alt="element.text" class="hidden mx-auto col-span-1 object-cover" style="width: 100%; height: 100%"
        :src="element.imgkit_url_picture ? element.imgkit_url_picture : (element.url_picture ? element.url_picture : '/static/logo_square.png')"
        @load="onImageLoad" :ref="element.id" />
    </div>

    <template v-if="moreInfo">
      <div class="mt-1 px-3 flex justify-start" style="height: 40px">
        <div class="flex">
          <img :src="
            element.liked === 0
              ? '/static/icons/jevi.svg'
              : '/static/icons/jeviFilled.svg'
          " alt="Jevi button" class="mr-2 my-auto" :class="element.liked === 0 ? 'dark:filter invert' : ''"
            style="width: 25px; cursor: pointer" @click="likePost(element)" />

          <p class="my-auto text-black dark:text-white text-sm">
            {{ calculateHighNumber(element.total_likes) }}
          </p>

          <img src="/static/icons/views.svg" alt="Views icon" class="ml-2 mr-2 my-auto dark:filter invert"
            style="width: 25px; cursor: default" />

          <p class="my-auto text-black dark:text-white text-sm">
            {{ calculateHighNumber(element.total_views) }}
          </p>
        </div>
      </div>

      <div class="px-3" style="height: 60px">
        <p class="my-0 py-0">
          <span class="text-black dark:text-white text-sm" v-html="$detectHashtagsAndUsernames(element.text.length > 40
          ? element.text.slice(0, 40) + ' ...' : element.text)" @click="handleProcessedTextClick"></span>
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import PostType from "@/components/post/PostType.vue"
import PostText from "@/components/post/PostText.vue"

export default {
  components: {
    ProfilePicture,
    PostType,
    PostText,
  },
  props: {
    user: {
      type: Object,
      default: () => { }
    },
    moreInfo: {
      type: Boolean,
      default: true
    },
    element: {
      type: Object,
      default: {}
    }
  },
  computed: {
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    OAuthPreferences() {
      return this.$store.getters["auth/getOAuthPreferences"]
    },
  },
  data() {
    return {}
  },
  methods: {
    onImageLoad() {
      if (this.$refs["loading-picture"])
        this.$refs["loading-picture"].classList.add("hidden")
      if (this.$refs[this.element.id])
        this.$refs[this.element.id].classList.remove("hidden")
    },
    calculateHighNumber(number) {
      if (isNaN(number)) return 0
      if (Number(number) == 1000000) return "1M"
      if (Number(number) > 1000000) return (number / 1000000).toFixed(2) + "M"
      if (Number(number) == 1000) return "1K"
      if (Number(number) > 1000) return (number / 1000).toFixed(2) + "K"

      return number
    },
    handleProcessedTextClick(e) {
      if (e.target.matches(".post-hashtag")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/hashtag/all",
            module: null,
            noPaginate: true,
            isMaster: true,
            params: {
              //query: e.target.innerHTML.slice(1),
              name: e.target.innerHTML,
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.$router.push("/post_by_hashtag/" + response.data[0].name.slice(1))
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
      if (e.target.matches(".post-username")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/user/all",
            module: null,
            noPaginate: true,
            params: {
              username: e.target.innerHTML.slice(1),
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.openProfile(response.data[0])
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
    likePost(post) {
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            type_child: "post",
            id_child: post.id,
            bearer_token:
              this.OAuthPreferences &&
                this.OAuthPreferences.accessToken &&
                this.OAuthPreferences.likes_comments
                ? this.OAuthPreferences.accessToken
                : null
          },
          route: "/like/save",
          module: null
        })
        .then((res) => { })
        .catch((error) => {
          console.log("post like error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    handleVideoPlay(postId, htmlElement) {
      if (!htmlElement.classList.contains("viewed")) {
        htmlElement.classList.add("viewed")
        this.$store
          .dispatch("global/update", {
            payload: {
              id: postId,
              new_view: 1
            },
            route: "/post/update",
            module: null
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
  },

  created() { }
}
</script>

<style lang="scss">
.owl-stage {
  display: flex;
  justify-content: stretch;
  align-items: start;
}

.owl-item {
  align-self: stretch;
}
</style>
