<template>
  <div class="w-full flex" style="overflow-x:hidden;">
    <div class="w-full sm:w-8/12 lg:w-4/6 xl:w-9/12">
      <div
        class="transition-transform ease-in-out delay-150 scale-0 duration-300 fixed left-1/2 transform -translate-x-1/2 text-black z-50 bg-white shadow-xl rounded-full border-gray-400 px-3 py-2"
        id="newPostsBtn" @click="triggerNewPosts" style="
          cursor: pointer;
          border-width: 1px;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
        ">
        {{ $i18n.t("new_posts") }}
      </div>
      <div class="mt-5 sm:ml-2 md:ml-4 lg:ml-2 w-full sm:w-4/5">
        <div class="pr-6">
          <story-bar @openStory="openStory" @openStoryCreation="openStoryCreation" />
        </div>

        <div class="mt-5">
          <Highlight :noAuth="false" :element="currentPost" :allowLike="true" :showComments="showComments" :allowComments="true"
            :allowSubscription="false" :OAuthPreferences="OAuthPreferences"
            :isLoadingSubscription="isLoadingSubscription" :calculateHighNumber="calculateHighNumber"
            @likePost="likePost" @toggleComments="toggleComments" @openModal="openLikesModal"
            @sharePost="$emit('sharePost', currentPost)" @subscribeChannel="subscribeChannel(currentPost)"
            :id="loggedUser ? loggedUser.id : null"
            @openProfile="openProfileFromLikesModal" />
        </div>

        <template>
          <div v-for="(element, index) in posts" :key="index">

            <div class="pr-6">
              <Cuts v-if="(index + 1) % 5 === 0 && (index + 1) % 10 !== 0" :buttons="false"
                :currentPost.sync="currentPost" :postModal.sync="postModal" />
              <Originals v-if="(index + 1) % 10 === 0" :buttons="false" :currentPost.sync="currentPost"
                :postModal.sync="postModal" />
            </div>

            <post-mode-element :element="element" :key="
              element.key
                ? 'feed-post-' + element.key
                : 'feed-post-' + element.id
            " @likedPost="likedPost" :user="{
  ...element.user,
  followed_by_you_status: element.followed_by_you_status
}" :canEdit="
  element.user && element.user.id === loggedUser.id ? true : false
" @postDeleted="handlePostDeletion" @userUpdate="handleUserUpdate" @commentDeleted="commentDeleted"
              @openPostReport="openPostReport" @updatePoll="updatePoll" @endPoll="endPoll" @sharePost="sharePost" />
            <template v-if="index !== 0 && index % 5 === 0">
              <!-- <in-feed-adsense
                :key="'feed-ad-' + index"
                data-ad-client="ca-pub-6627944524690313"
                data-ad-slot="6847062496"
                data-ad-test="on"
                data-ad-format="fluid"
                data-ad-layout-key="-6t+ed+2i-1n-4w"
                :data-full-width-responsive="'yes'"
              /> -->
              <adsense :key="'feed-ad-' + index" data-ad-client="ca-pub-6627944524690313" data-ad-slot="5346751326"
                data-ad-format="auto" data-full-width-responsive="yes" data-ad-test="on" is-new-ads-code="yes" />
            </template>
          </div>
        </template>
      </div>

      <t-button class="bg-primary-1000 mx-auto" v-if="posts.length > 0 && !reachedLast" @click="loadMore">{{
          $i18n.t("load_more_posts")
      }}</t-button>

      <div v-if="reachedLast">
        <p class="text-center mx-auto text-black dark:text-white">
          {{ $i18n.t("no_more_posts") }}
        </p>
      </div>
    </div>
    <div class="overflow-y-scroll hidden sm:block lg:block xl:block h-full fixed right-0 mr-2 px-4  w-2/6">
      <div v-if="!suggestedUsersLoading" :variant="theme === 'dark' ? 'dark' : 'light'"
        class="suggestions-sidebar w-full ">
        <p class="text-black dark:text-white px-2 mb-3 text-center font-bold">
          {{ $i18n.t("recommended_users") }}
        </p>

        <div class="w-full mb-2" v-for="user in suggestedUsers.slice(0, 2)" :key="'recommended-user-' + user.id">
          <img class="w-full object-cover z-20" style="max-height: 150px; cursor: pointer; border-radius: 10px"
            src="/static/logo_square.png" :ref="'loading-picture-' + user.id" />
          <img class="hidden w-full object-cover z-20" style="max-height: 150px; cursor: pointer; border-radius: 10px"
            :src="
              user.header_picture
                ? user.header_picture
                : '/static/banner-placeholder.jpg'
            " alt="Profile header" @load="onImageLoad(user.id)" :ref="'user-header-' + user.id"
            @click="openProfile(user)" />

          <div class="w-full px-3">
            <profile-card :user="user" :canEdit="false" :allowFollow="true" :showFollowModals="false"
              @click="openProfile(user)" @userUpdate="updateSuggestedUser" :clickToProfile="true" :cardWidth="'w-11/12'"
              :profilePictureSize="'20'" :smallFont="true" style="cursor: pointer" :limitBiography="true" />
          </div>
        </div>


        <div class="pb-24">
          <p class="mt-3   mb-2 text-black dark:text-white px-2 text-center font-bold">
            TOP 10 HASHTAGS
          </p>

          <div class="w-full pb-5 grid grid-flow-row-dense md:grid-cols-1 sm:grid-cols-1 sm:gap-1 gap-2">
            <div v-for="(hashtag, index) in hashtags_top10" :key="hashtag.id" class="mx-5 flex justify-between"
              style="min-height: 10px;">
              <p class="text-sm" :class="theme === 'dark' ? 'text-white' : 'text-white'">
                <span :class="theme === 'dark' ? 'text-white' : 'text-white'">{{ (index + 1) + '. ' }} </span>
                <span class='text-primary-1100 font-bold post-hashtag' style="cursor: pointer;"
                  @click="openPostsByHashtag(hashtag)">{{ hashtag.name }} </span>
              </p>

              <p class="text-sm" :class="theme === 'dark' ? 'text-white' : 'text-white'">
                <span :class="theme === 'dark' ? 'text-white' : 'text-white'">{{
                    calculateHighNumber(hashtag.total_posts)
                }} {{
    hashtag.total_posts == 1 ? 'post' : 'posts'
}}</span>
              </p>
            </div>

          </div>
        </div>


      </div>
      <img v-if="suggestedUsersLoading" class="w-1/2 md:w-1/6 lg:w-1/5 mx-auto mt-24" :src="
        theme === 'dark'
          ? '/static/loading_darkmode.gif'
          : '/static/loading_lightmode.gif'
      " alt="loading-gif" />
    </div>
    <t-modal header="" :value="postModal" @closed="closeModal('postModal')" variant="post">
      <div class="block md:flex">
        <div class="mx-auto w-full md:w-3/5">
          <t-card style="background-color: transparent;">
            <template>


              <div>
                <PostType :element="currentPost" :sizePx="-1" :loggedUserID="loggedUser ? loggedUser.id : null" />

                <ActionBar :noAuth="false" :element="currentPost" :allowLike="true" :allowComments="false"
                  :allowSubscription="true" :OAuthPreferences="OAuthPreferences"
                  :isLoadingSubscription="isLoadingSubscription" :calculateHighNumber="calculateHighNumber"
                  @likePost="likePost" @toggleComments="toggleComments" @openModal="openLikesModal"
                  @sharePost="$emit('sharePost', currentPost)" @subscribeChannel="subscribeChannel(currentPost)"
                  :id="loggedUser ? loggedUser.id : null" @openProfile="openProfileFromLikesModal" />

                <PostText :element="currentPost" />
              </div>
            </template>
          </t-card>
        </div>

        <div class="w-full md:w-2/5 flex flex-col content-between px-2 mt-2">

          <comments @openProfile="openProfile" :post="currentPost" :showNewComment="true" :height="'85vh'"
            @commentDeleted="currentPost.total_comments--" />
        </div>
      </div>
    </t-modal>
  </div>
</template>

<script>
import StoryBar from "@/components/story/StoryBar.vue"
import Highlight from "@/components/highlight/Highlight.vue"
import JeviCuts from "@/components/story/JeviCuts.vue"
import Cuts from "@/views/video/tabs/Cuts.vue"
import Originals from "@/views/video/tabs/Originals.vue"
import PostModeElement from "@/components/gallery/PostModeElement.vue"
import Likes from "@/components/post/likes.vue"
import ActionBar from "@/components/post/ActionBar.vue"
import PostText from "@/components/post/PostText.vue"
import PostType from "@/components/post/PostType.vue"
import Comments from "@/components/post/comments.vue"
import ProfileCard from "@/components/user/ProfileCard.vue"
const notify = () => import("@/utils/notify.js")
import { debounce } from "lodash"

export default {
  components: {
    StoryBar,
    Highlight,
    Cuts,
    Originals,
    JeviCuts,
    PostModeElement,
    ProfileCard,
    Likes,
    ActionBar,
    PostText,
    PostType,
    Comments
  },
  data() {
    return {
      page: 1,
      loading: true,
      posts: [],
      postModal: false,
      currentPost: {
        comments: []
      },
      showComments: false,
      hashtags_top10: [],
      reachedLast: false,
      suggestedUsersLoading: true,
      likesModal: false,
      postID: 0,
      isLoadingSubscription: false,
      itemsAmount: Math.floor((window.screen.height * 0.84) / 255)
    }
  },
  computed: {
    OAuthPreferences() {
      return this.$store.getters["auth/getOAuthPreferences"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    loadNewPosts() {
      return this.$store.getters["post/getLoadNewPosts"]
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    suggestedUsers() {
      return this.$store.getters["user/getAllSuggested"]
    }
  },
  methods: {
    likedPost(post) {
      const idx = this.posts.map(p => p.id).indexOf(post.id)
      this.posts[idx] = post
    },
    openLikesModal(postID) {
      // this.followListLoader = true
      this.postID = postID
      this.likesModal = true
    },
    openProfileFromLikesModal(user) {
      this.postModal = false
      this.likesModal = false

      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    toggleComments() {
      this.showComments = !this.showComments
    },
    likePost(post) {
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            type_child: "post",
            id_child: post.id,
            bearer_token:
              this.OAuthPreferences &&
                this.OAuthPreferences.accessToken &&
                this.OAuthPreferences.likes_comments
                ? this.OAuthPreferences.accessToken
                : null
          },
          route: "/like/save",
          module: null
        })
        .catch((error) => {
          console.log("post like error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    subscribeChannel(element) {
      this.isLoadingSubscription = true
      this.$store
        .dispatch("global/create", {
          payload: {
            bearer_token:
              this.OAuthPreferences &&
                this.OAuthPreferences.accessToken &&
                this.OAuthPreferences.subscriptions
                ? this.OAuthPreferences.accessToken
                : null
          },
          route: "/post/subscribe_channel/" + element.id
        })
        .then((response) => {
          if (response.code != 500) {
            element.subscribed_to_channel = 1
          }

          notify().then(({ notification }) => {
            notification(
              "channel_subscribed",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          this.isLoadingSubscription = false
          this.$emit("subscribedChannel", response.data)
        })
        .catch((error) => {
          console.log(error)
          this.isLoadingSubscription = false
          notify().then(({ notification }) => {
            notification("error", 500, this.$snotify, this.$i18n.locale)
          })
        })
    },
    closeModal(modalName) {
      this[modalName] = false
      this.commentText = ""

    },
    sharePost(data) {
      this.$emit("sharePost", data)
    },
    openStoryCreation() {
      this.$emit("update:storyCreationModal", true)
    },
    openStory(story) {
      this.$emit("openStory", story)
    },
    resumablePostVideo() {
      return this.$store.getters["post/getResumableVideo"]
    },
    endPoll(data) {
      const index = this.posts.findIndex((x) => x.id === data.id)
      if (index !== -1) {
        Object.assign(this.posts[index], {
          ...this.posts[index],
          ended: 1
        })
      }
    },
    updatePoll(data) {
      console.log(data)
      const index = this.posts.findIndex((x) => x.id === data.poll.id)
      if (index !== -1) {
        let datax = this.posts[index]
        datax.questions = data.questions
        datax.answers = data.total_answers
        data.key = new Date()

        Object.assign(this.posts[index], datax)
      }
    },
    openPostReport(post) {
      this.$emit("openPostReport", post)
    },
    updateSuggestedUser(data) {
      this.$store.dispatch("user/updateSuggestedUsers", data)
    },
    onImageLoad(id) {
      if (this.$refs["loading-picture-" + id][0])
        this.$refs["loading-picture-" + id][0].classList.add("hidden")
      if (this.$refs["user-header-" + id][0])
        this.$refs["user-header-" + id][0].classList.remove("hidden")
    },

    handler() {
      if (this.postModal) {
        this.$nextTick(function () {
          if (this.currentPost && this.currentPost.type === 2) {
            const intervalId = setInterval(() => {
              let currentPostId = this.currentPost.id
              if (
                document.getElementById(`main-video-player-${currentPostId}`)
              ) {
                window.handleVideoPlay = this.handleVideoPlay

                function onPlayerStateChange(event) {
                  let currentPostId = event.target.h.id.split("-player-")[1]
                  let htmlId = event.target.h.id
                  if (event.data == YT.PlayerState.PLAYING) {
                    window.handleVideoPlay(
                      parseInt(currentPostId),
                      document.getElementById(htmlId).classList.add("viewed")
                    )
                  }
                }

                const player = new YT.Player(
                  `main-video-player-${currentPostId}`,
                  {
                    playerVars: {
                      origin: window.location.host,
                      widget_referrer: window.location.host
                    },
                    events: {
                      onStateChange: onPlayerStateChange
                    }
                  }
                )
                clearInterval(intervalId)
              }
            }, 100)
          }
        })
      }

    },
    handleVideoPlay(postId, htmlElement) {
      if (!htmlElement.classList.contains("viewed")) {
        htmlElement.classList.add("viewed")
        this.$store
          .dispatch("global/update", {
            payload: {
              id: postId,
              new_view: 1
            },
            route: "/post/update",
            module: null
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
    getHashtagsTop10() {
      this.$store
        .dispatch("global/getItems", {
          route: "/hashtag/all",
          module: null,
          noPaginate: true,
          isMaster: true,
          params: {
            order_key: "total_posts",
            order_value: "desc",
            limit: 10,
            active: 1
          }
        })
        .then((response) => {
          this.hashtags_top10 = response.data
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    openProfile(user) {
      if (this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    commentDeleted(comment) {
      let index = this.posts.findIndex((x) => x.id === comment.id_post)
      if (index !== -1) this.posts[index].total_comments--
    },
    triggerNewPosts() {
      this.$store.dispatch("post/setLoadNewPosts", true)
    },
    openPostsByHashtag(hashtag) {
      this.$router.push("/post_by_hashtag/" + hashtag.name.slice(1))
    },
    handlePostDeletion(post) {
      let index = this.posts.findIndex((x) => x.id === post.id)
      if (index !== -1) {
        this.posts.splice(index, 1)
      }
    },
    handleUserUpdate({ post, user }) {
      let index = this.posts.findIndex((x) => x.id === post.id)
      if (index !== -1) {
        this.posts[index].user = user
        this.posts[index].followed_by_you_status = user.followed_by_you_status
      }
    },
    loadMore() {
      this.page++
      this.loading = true
      this.getPosts()
    },
    getSuggestedUsers() {
      this.$store
        .dispatch("global/getItems", {
          route: `/user/top_10/${this.loggedUser.id}`,
          module: null,
          noPaginate: true
        })
        .then((response) => {
          this.suggestedUsersLoading = false
          const user_data = response.data.data.map((x) => x.user)
          for (let i in user_data) user_data[i].followed_by_you_status = 0
          this.$store.dispatch("user/setSuggestedUsers", user_data)
        })
        .catch((error) => {
          this.suggestedUsersLoading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getPosts() {
      this.$store
        .dispatch("global/getItemsV2", {
          route: `/post/feed/${this.loggedUser.id}`,
          module: null,
          noPaginate: true,
          params: {
            order_key: "created_at",
            order_value: "desc",
            page: this.page,
            limit: 10
          }
        })
        .then((response) => {
          if (response.data.new_posts === 1) {
            document.getElementById("newPostsBtn").classList.remove("scale-0")
          }
          this.posts = this.posts.concat(response.data.posts)
          if (response.data.posts.length === 0) this.reachedLast = true
          this.$emit("finishLoading")
          this.loading = false
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    calculateHighNumber(number) {
      if (isNaN(number)) return 0
      if (Number(number) == 1000000) return "1M"
      if (Number(number) > 1000000) return (number / 1000000).toFixed(2) + "M"
      if (Number(number) == 1000) return "1K"
      if (Number(number) > 1000) return (number / 1000).toFixed(2) + "K"

      return number
    },
    debouncedResize() {
      this.itemsAmount = Math.floor((window.screen.height * 0.84) / 255)
    }
  },
  created() {
    this.$emit("startLoading")
    this.getPosts()
    this.getHashtagsTop10()
    window.vueComponent = this

    window.onscroll = function () {
      let total_scrollable_height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      if (Math.round(window.scrollY) / total_scrollable_height > 0.7) {
        if (!this.vueComponent.reachedLast && !this.vueComponent.loading) {
          this.vueComponent.page++
          this.vueComponent.loading = true
          this.vueComponent.getPosts()
        }
      }
    }
    this.getSuggestedUsers()
    this.$store.dispatch("post/setLoadNewPosts", false)
    window.addEventListener("resize", debounce(this.debouncedResize, 500))
  },
  beforeDestroy() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },
  unmounted() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },
  watch: {
    loadNewPosts: {
      handler() {
        if (this.loadNewPosts) {
          this.page = 1
          this.$emit("startLoading")
          this.posts = []
          this.reachedLast = false
          this.getPosts()
          this.$store.dispatch("post/setLoadNewPosts", false)
          window.scrollTo(0, 0)
        } else {
          document.getElementById("newPostsBtn").classList.add("scale-0")
        }
      },
      deep: true
    },
  }
}
</script>
