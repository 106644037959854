<template>
    <div class="flex">
        <div class="w-full md:w-3/5 lg:w-4/6">
            <div class="mb-5 px-5 md:px-3">
                <p class="text-black dark:text-white text-md sm:text-lg md:text-2xl mb-3">
                    JEVI CUTS
                </p>
                <carousel :nav="true" :dots="false" class="carousel-card video-carousel relative" :margin="15"
                    :responsive="{
                        0: { items: 1, nav: false },
                        340: { items: 1, nav: false },
                        650: { items: 2, nav: false },
                        800: { items: 3, nav: false }
                    }">


                    <video-preview v-for="group in jeviCuts" :key="'video-group-' + group.id" :element="group"
                        :user="group.user" @click.native="openPost(group)" />

                    <template slot="prev"><span
                            class="absolute bg-white top-1/3 -left-5 p-2 rounded-lg shadow-xl z-10 cursor-pointer"
                            style="border: 1px solid #a0a0a0">
                            <icon icon="angle-left" />
                        </span></template>
                    <template slot="next"><span
                            class="absolute bg-white top-1/3 -right-5 p-2 rounded-lg shadow-2xl z-10 cursor-pointer"
                            style="border: 1px solid #a0a0a0">
                            <icon icon="angle-right" />
                        </span></template>
                </carousel>
            </div>
        </div>
        <div class="hidden md:block md:w-2/5 lg:w-2/6 pl-5">
            <t-card :variant="theme === 'dark' ? 'dark' : 'light'" class="md:w-2/5 lg:w-2/6 fixed pl-5"
                style="cursor: pointer">
                <template v-slot:header>
                    <p class="text-black dark:text-white px-2 text-center font-bold">
                        {{ $i18n.t("recommended_videos") }}
                    </p>
                </template>

                <div class="my-1 w-full" v-for="post in suggestedVideos" :key="'recommended-post-' + post.id">
                    <div class="flex justify-start" @click="openPost(post)">
                        <img style="max-width: 100px; aspect-ratio: 1" src="/static/logo_square.png"
                            :ref="'loading-picture-' + post.id" />

                        <img class="hidden" style="max-width: 100px; aspect-ratio: 1" @load="onImageLoad(post.id)"
                            :src="post.url_picture" :ref="'video-preview-' + post.id" alt="Video preview" />
                        <div>
                        </div>
                    </div>
                </div>
            </t-card>
        </div>
    </div>
</template>
  
<script>
import ProfilePicture from "@/components/user/ProfilePicture.vue"
const notify = () => import("@/utils/notify.js")
import carousel from "vue-owl-carousel"
import VideoPreview from "@/components/gallery/VideoPreview.vue"
import { debounce } from "lodash"

export default {
    components: {
        carousel,
        ProfilePicture,
        VideoPreview
    },
    props: {},
    computed: {
        theme() {
            return this.$store.getters["getTheme"]
        },
        suggestedVideos() {
            return this.$store.getters["jeviCuts/getAllUnpaginated"]
        },
        loggedUser() {
            return this.$store.getters["auth/getUser"]
        },
        jeviCutsUsers() {
            return this.$store.getters["jeviCuts/getItem"]
        },
        loadNew() {
            return this.$store.getters["jeviCuts/getLoadNew"]
        }
    },
    data() {
        return {
            loading: true,
            jeviCuts: []
        }
    },

    methods: {
        openPost(post) {
            this.$emit("update:currentPost", post)
            this.$emit("update:postModal", true)
            this.$socket.emit("post_viewed", {
                id_user: this.loggedUser.id,
                id_post: post.id
            })
        },

        createJeviCuts() {
            this.$emit("openjeviCutsCreation")
        },
        openJeviCuts(jeviCuts) {
            this.$emit("openjeviCuts", jeviCuts)
        },
        onImageLoad(element) {
            if (this.$refs["loading-picture"])
                this.$refs["loading-picture"].classList.add("hidden")
            if (this.$refs[element.id] && this.$refs[element.id].classList)
                this.$refs[element.id].classList.remove("hidden")
            if (this.$refs[element.id][0] && this.$refs[element.id][0].classList)
                this.$refs[element.id][0].classList.remove("hidden")
        },
        // getUsersWithStories() {
        //     this.$store
        //         .dispatch("global/getItems", {
        //             route: "/jeviCuts/feed/" + this.loggedUser.id,
        //             noPaginate: true,
        //             module: "jeviCuts",
        //         })
        //         .then((response) => {
        //             if (this.loggedUser.stories_available) {
        //                 this.getUserStories()
        //             } else {
        //                 this.loading = false
        //             }
        //         })
        //         .catch((error) => {
        //             notify().then(({ notification }) => {
        //                 notification("error", error.code, this.$snotify, this.$i18n.locale)
        //             })
        //         })
        // },
        getUserStories() {
            this.$store
                .dispatch("global/getItems", {
                    route: "/post/videos_suggestions?page=1&slimit=1",
                    noPaginate: true,
                })
                .then((response) => {
                    this.$store.dispatch("jeviCuts/addUserCut", response.data)
                    console.log(response.data);
                    this.jeviCuts = response.data
                    this.loading = false

                })
                .catch((error) => {
                    notify().then(({ notification }) => {
                        notification("error", error.code, this.$snotify, this.$i18n.locale)
                    })
                })
        }
    },
    created() {
        this.getUserStories()
    },
    watch: {
        jeviCutsUsers: {
            handler() {
                if (
                    this.jeviCutsUsers.length > 0 &&
                    this.jeviCutsUsers[0].id === this.loggedUser.id
                ) {
                    this.loading = false
                }
            },
            deep: true
        },
        loadNew: {
            handler() {
                if (this.loadNew) {
                    this.loading = true
                    this.$store.dispatch("jeviCuts/setLoadNew", false)
                }
            }
        }
    }
}
</script>
  
<style>
.carousel-card .owl-nav {
    display: none;
}

.video-carousel .owl-carousel .owl-item {
    width: 274px !important;
}
</style>
  